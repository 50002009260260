/* eslint-disable */
import { LoadingOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Form, Input, Row, Spin, Table, Tabs, Tag, Tooltip, message } from 'antd';
import type { ColumnsType, SorterResult } from "antd/es/table/interface";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import InstitutionUserService from "../../services/InstitutionUserService";
import TabPane = Tabs.TabPane


const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#37762F' }} spin />
const { RangePicker } = DatePicker
interface DataType {
    index: any;
    key: React.Key
    bank_code: string
    transaction_reference: string
    debit_account_number: string
    debit_account_name: string
    beneficiary_account_name: string
    beneficiary_bank_name: string
    beneficiary_account_number: string
    transaction_currency: string
    amount: number
    reason: string
    status: string
    created_at: string;
    updated_at: string;
    created_by: string;
    column: string;
    totalPayments: number;
    amountRequestedToBeRefund: number;
    amountRequestedToBeRefundedInProgressStatus: number;
    TotalNumberOfApplicantRequestedRefund: number;
    notRequestedBidders: number;
}
const BiddersRefundRequest: React.FC = () => {
    const [form] = Form.useForm()
    const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}')
    const navigate = useNavigate()
    const [filterStatus, setfilterStatus] = useState('')
    const [clear, setClear] = useState(false)
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [refundRequestList, setRefundRequestList] = useState<DataType[]>([]);
    const [loading, setLoading] = useState(false);
    const [sortedInfo, setSortedInfo] = useState<SorterResult<DataType>>({})
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10
    })
    const [bailiffRefundRequestList, setBailiffRefundRequestList] = useState<DataType[]>([]);
    const [stats, setStats] = React.useState<DataType | null>(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const [activeTab, setActiveTab] = useState('bidders'); // Default tab
    const [appliedFilters, setAppliedFilters] = useState<string[]>([]);
    const [token, setToken] = useState('')
    const [BiddersAmountRequestedToBeRefund, setBiddersAmountRequestedToBeRefund] = useState('')
    const [BailiffAmountRequestedToBeRefunded, setBailiffAmountRequestedToBeRefunded] = useState('')
    const [NewBiddersAmountRequestedToBeRefunded, setNewBiddersAmountRequestedToBeRefunded] = useState('')
    const [NewBailiffAmountRequestedToBeRefunded, setNewBailiffAmountRequestedToBeRefunded] = useState('')
    const [TotalPayments, setTotalPayments] = useState('')

    useEffect(() => {
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          async function fetchRefundStats () {
              setLoading(true)
              try {
                  const response = await InstitutionUserService.viewRefundStatsByRefundStartDate(fromDate, toDate, token)
                  const biddersAmountRequestedToBeRefund = response.data?.biddersAmountRequestedToBeRefund
                  setBiddersAmountRequestedToBeRefund(response.data?.biddersAmountRequestedToBeRefund)
                  const bailiffAmountRequestedToBeRefunded = response.data?.bailiffAmountRequestedToBeRefunded
                  setBailiffAmountRequestedToBeRefunded(response.data?.bailiffAmountRequestedToBeRefunded)
                  const newBiddersAmountRequestedToBeRefunded = response.data?.newBiddersAmountRequestedToBeRefunded
                  setNewBiddersAmountRequestedToBeRefunded(response.data?.newBiddersAmountRequestedToBeRefunded)
                  const newBailiffAmountRequestedToBeRefunded = response.data?.newBailiffAmountRequestedToBeRefunded
                  setNewBailiffAmountRequestedToBeRefunded(response.data?.newBailiffAmountRequestedToBeRefunded)
                  const totalPayments = response.data?.totalPayments
                  setTotalPayments(response.data?.totalPayments)
              } catch (err) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  setError('Failed to fetch refund stats')
                  // eslint-disable-next-line no-console
                  console.error(err)
              } finally {
                  setLoading(false)
              }
          }

          void fetchRefundStats()
      },
      [fromDate, toDate, token])

    useEffect(() => {
        // Fetch data on component mount
        OnRefundRequestSearch();
    }, []);

    useEffect(() => {
        if (activeTab === 'bidders') {
            const total = refundRequestList.reduce((acc, curr) => acc + curr.amount, 0);
            setTotalAmount(total);
        } else if (activeTab === 'bailiffs') {
            const total = bailiffRefundRequestList.reduce((acc, curr) => acc + curr.amount, 0);
            setTotalAmount(total);
        }
    }, [refundRequestList, bailiffRefundRequestList, activeTab]);

    React.useEffect(() => {
        InstitutionUserService.RefundRequestStats()
            .then((res: any) => {
                setStats(res.data); // assuming the stats are in res.data
            })
            .catch((err) => {
                message.error(err?.message);
            });
    }, []);

    useEffect(() => {
        OnRefundRequestSearch()
        // eslint-disable-next-line
    }, [clear, pagination.current, pagination.pageSize, fromDate, toDate])
    const handleTableChange = (pagination: any) => {
        setPagination(pagination) // Update pagination state on page change
    }
    let filter = ''
    if (filterStatus) {
        filter = filter + 'Type'
    }
    if (fromDate) {
        filter = filter === '' ? filter + 'From Date' : filter + ', From Date'
    }
    if (toDate) {
        filter = filter === '' ? filter + 'To Date' : filter + ', To Date'
    }

    const updateRefundRequestStatus = async (): Promise<void> => {
        try {
            const refundRequests = refundRequestList.map(item => ({ transaction_reference: item.transaction_reference }));
            const response = await InstitutionUserService.updateRefundRequestStatus({ RefundRequests: refundRequests });

            if (response.status === 200) {
                // Handle success
            } else {
                // Handle unexpected response status
            }
        } catch (error) {
            // Handle error
        }
    };

    const updateBailiffRefundRequestStatus = async (): Promise<void> => {
        try {
            const createdBy = userInfo?.id || 'default_user_id';
            const bailiffRefundRequests = bailiffRefundRequestList.map(item => ({ transaction_reference: item.transaction_reference, created_by: createdBy }));
            const response = await InstitutionUserService.updateBailiffRefundRequestStatus({ BailiffRefundRequests: bailiffRefundRequests });

            if (response.status === 200) {
                // Handle success
            } else {
                // Handle unexpected response status
            }
        } catch (error) {
            // Handle error
        }
    };

    const columnsToDownload = [
        'bank_code',
        'transaction_reference',
        'debit_account_number',
        'debit_account_name',
        'beneficiary_bank_name',
        'beneficiary_account_name',
        'beneficiary_account_number',
        'amount',
        'transaction_currency',
        'reason'
    ];
    // Convert column names to uppercase and remove underscores
    const formattedColumns = columnsToDownload.map(column =>
        column.toUpperCase().replace(/_/g, ' ')
    );

    const convertToCSV = (data: { [x: string]: any; }[], maxColumns: number = 10) => {
        const columnsToSkip = ['refund_request_id', 'bailiff_refund_request_id'];
        const originalColumns = Object.keys(data[0])
            .filter(col => !columnsToSkip.includes(col))
            .slice(0, maxColumns);

        // Remove underscores and convert to uppercase for display
        const formattedColumns = originalColumns.map(column =>
            column.toUpperCase().replace(/_/g, ' ')
        );

        // Create CSV header
        const header = formattedColumns.join(',') + '\n';

        // Create CSV rows
        const rows = data.map((row: { [x: string]: any; }) => {
            const rowData = formattedColumns.map((col) => {
                // Convert header back to original format to match data keys
                const column = col.toLowerCase().replace(/ /g, '_');
                let value = row[column];
                if (value === undefined || value === null) return '';
                value = value.toString().replace(/"/g, '""'); // Escape quotes
                return `"${value}"`; // Enclose in quotes
            }).join(',');

            return rowData;
        }).join('\n');

        return header + rows;
    };

    const downloadCSV = (csvContent: string, fileName: string, maxColumns: number = 10) => {
        const lines = csvContent.split('\n');
        const headerLine = lines[0].split(',');
        const transactionReferenceIndex = headerLine.indexOf('TRANSACTION REFERENCE');
        const debitAccountNumberIndex = headerLine.indexOf('DEBIT ACCOUNT NUMBER');
        const beneficiaryAccountNumberIndex = headerLine.indexOf('BENEFICIARY ACCOUNT NUMBER');
        const formattedLines = lines.map((line, lineIndex) => {
            const columns = line.split(',').slice(0, maxColumns);

            if (lineIndex > 0 && transactionReferenceIndex !== -1) {
                columns[transactionReferenceIndex] = '';
            }

            if (debitAccountNumberIndex !== -1) {
                // Remove any single quotes and ensure proper CSV formatting
                columns[debitAccountNumberIndex] = `"${columns[debitAccountNumberIndex].replace(/'/g, "''").replace(/"/g, '""')}"`;
            }

            if (beneficiaryAccountNumberIndex !== -1) {
                // Remove any single quotes and ensure proper CSV formatting
                columns[beneficiaryAccountNumberIndex] = `"${columns[beneficiaryAccountNumberIndex].replace(/'/g, "''").replace(/"/g, '""')}"`;
            }

            const formattedColumns = columns.map(column => {
                return column.replace(/"/g, ''); // Remove quotes
            }).join(',');

            return formattedColumns;
        });
        // Join all formatted lines into the final CSV content
        const formattedCSV = formattedLines.join('\n');
        const blob = new Blob([formattedCSV], { type: 'text/csv;charset=utf-8;' });

        if ((navigator as any).msSaveBlob) { // For IE 10+
            (navigator as any).msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error('File download not supported on this browser.');
            }
        }
    };

    const handleDownloadExcel = async (): Promise<void> => {
        try {
            let dataToDownload: DataType[] = [];
            if (activeTab === 'bidders') {
                dataToDownload = refundRequestList;
                await updateRefundRequestStatus();
            } else if (activeTab === 'bailiffs') {
                dataToDownload = bailiffRefundRequestList;
                await updateBailiffRefundRequestStatus();
            }

            const csvContent = convertToCSV(dataToDownload, 10);
            downloadCSV(csvContent, 'BankTransfersTemplate.csv');
        } catch (error) {
            console.error('Error occurred while downloading CSV:', error);
            message.warning("Error occurred while downloading CSV and updating refund request status.");
        }
    };
    // Converts data to sheet format and then to an Excel file
    const convertToExcel = (data: { [x: string]: any }[], maxColumns: number = 10): XLSX.WorkBook => {
        console.log('Converting data to Excel format...');

        // Define columns to be skipped
        const columnsToSkip = ['refund_request_id', 'bailiff_refund_request_id'];

        // Extract headers while excluding columns to be skipped
        const originalColumns = Object.keys(data[0])
            .filter(col => !columnsToSkip.includes(col))
            .slice(0, maxColumns);

        console.log('Original Columns:', originalColumns);

        // Prepare data for the worksheet
        const sheetData = data.map(row => {
            const rowData: { [key: string]: any } = {};
            originalColumns.forEach(column => {
                // Convert header back to original format to match data keys
                const columnKey = column.toLowerCase().replace(/ /g, '_');
                rowData[column] = row[columnKey] || '';
            });
            return rowData;
        });

        console.log('Sheet Data:', sheetData);
        // Create a worksheet from the data
        const ws = XLSX.utils.json_to_sheet(sheetData, { header: originalColumns });
        // Create a workbook and add the worksheet to it
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        console.log('Excel workbook created.');

        return wb;
    };

    // Downloads the Excel file
    const downloadExcel = (wb: XLSX.WorkBook, fileName: string) => {
        console.log('Preparing to download Excel file...');

        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        if ((navigator as any).msSaveBlob) { // For IE 10+
            (navigator as any).msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error('File download not supported on this browser.');
            }
        }

        console.log('Excel file downloaded.');
    };

    // Handle the process of downloading the Excel file
    const handleDownloadExcelFile = async (): Promise<void> => {
        try {
            console.log('Handling download of Excel file...');

            let dataToDownload: { [x: string]: any }[] = [];
            if (activeTab === 'bidders') {
                dataToDownload = refundRequestList;
            } else if (activeTab === 'bailiffs') {
                dataToDownload = bailiffRefundRequestList;
            }

            console.log('Data to download:', dataToDownload);

            const wb = convertToExcel(dataToDownload, 10);
            downloadExcel(wb, 'New Refund Request Report.xlsx');
        } catch (error) {
            console.error('Error occurred while downloading Excel:', error);
            message.warning("Error occurred while downloading Excel and updating refund request status.");
        }
    };

    const clearFilter = (): void => {
        setfilterStatus('')
        form.setFieldValue('filterStatus', '')
        form.setFieldValue('range_picker', ['', ''])
        setFromDate('')
        setToDate('')
        setClear(true)
    }

    const handleTabChange = (key: string) => {
        setActiveTab(key);
    };

    useEffect(() => {
        OnRefundRequestSearch();
    }, [activeTab, pagination.pageSize, pagination.current, filterStatus, fromDate, toDate]);

    const OnRefundRequestSearch = (event?: any): void => {
        setLoading(true);
        const service = activeTab === 'bidders' ? InstitutionUserService.GetRefundRequestList : InstitutionUserService.GetBailiffRefundRequestList;
        service(
            pagination.pageSize as number,
            pagination.current as number,
            event?.target?.value,
            fromDate,
            toDate
        )
            .then((res: any) => {
                if (res.status === 200) {
                    if (activeTab === 'bidders') {
                        setRefundRequestList(res?.data);
                    } else if (activeTab === 'bailiffs') {
                        setBailiffRefundRequestList(res?.data);
                    }
                    setPagination((prev) => ({
                        ...prev,
                        total: res?.meta?.total
                    }));
                    setLoading(false);
                }
            })
            .catch((err: any) => {
                if (err?.message !== 'Operation canceled due to new request.') {
                    message.error(err?.message);
                }
                setLoading(false);
            });
    };

    const biddersColumns: ColumnsType<DataType> = [
        {
            title: 'No.',
            dataIndex: 'index',
            key: 'index',
            width: 90,
            render: (text, record, index) => {
                const pageNumber = pagination.current || 1
                const pageSize = pagination.pageSize || 10
                const globalIndex = (pageNumber - 1) * pageSize + (index + 1)
                return <span>{globalIndex}</span>;
            },
            sorter: (a, b) => a.index - b.index,
            sortOrder: sortedInfo.columnKey === 'index' ? sortedInfo.order : null,
            ellipsis: false,
        },
        {
            title: 'Transaction Reference',
            dataIndex: 'transaction_reference',
            key: 'transaction_reference',
            width: 100,
            sorter: (a: any, b: any) => a?.transaction_reference.localeCompare(b?.transaction_reference),
            sortOrder: sortedInfo?.columnKey === 'transaction_reference' ? sortedInfo?.order : null,
            ellipsis: true,
            render: (text: string, record: any) => (
                <Tooltip
                    title={`
                Bank Code: ${record.bank_code}
                Debit Bank Name: ${record.debit_account_name}
                Debit Bank Number: ${record.debit_account_number}
                Reason: ${record.reason}
            `}>
                    <span>{text}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Beneficiary Account Name',
            dataIndex: 'beneficiary_account_name',
            key: 'beneficiary_account_name',
            width: 200,
            sorter: (a: any, b: any) =>
                a?.beneficiary_account_name.localeCompare(b?.beneficiary_account_name),
            sortOrder: sortedInfo?.columnKey === 'beneficiary_account_name' ? sortedInfo?.order : null,
            ellipsis: true,
            render: (text: string, record: any) => (
                <Tooltip
                    title={`
                Bank Code: ${record.bank_code}
                Debit Bank Name: ${record.debit_account_name}
                Debit Bank Number: ${record.debit_account_number}
                Reason: ${record.reason}
            `}>
                    <span>{text}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Beneficiary Bank Name',
            dataIndex: 'beneficiary_bank_name',
            key: 'beneficiary_bank_name',
            width: 200,
            sorter: (a: any, b: any) => a?.beneficiary_bank_name.localeCompare(b?.beneficiary_bank_name),
            sortOrder: sortedInfo?.columnKey === 'beneficiary_bank_name' ? sortedInfo?.order : null,
            ellipsis: true,
            render: (text: string, record: any) => (
                <Tooltip
                    title={`
                Bank Code: ${record.bank_code}
                Debit Bank Name: ${record.debit_account_name}
                Debit Bank Number: ${record.debit_account_number}
                Reason: ${record.reason}
            `}>
                    <span>{text}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Beneficiary Account Number',
            dataIndex: 'beneficiary_account_number',
            key: 'beneficiary_account_number',
            width: 150,
            sorter: (a: any, b: any) => a?.beneficiary_account_number.localeCompare(b?.beneficiary_account_number),
            sortOrder: sortedInfo?.columnKey === 'beneficiary_account_number' ? sortedInfo?.order : null,
            ellipsis: true,
            render: (text: string, record: any) => (
                <Tooltip
                    title={`
                Bank Code: ${record.bank_code}
                Debit Bank Name: ${record.debit_account_name}
                Debit Bank Number: ${record.debit_account_number}
                Reason: ${record.reason}
            `}>
                    <span>{text}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 130,
            render: (_text: any, record: any) => {
                const amount = parseInt(record?.amount, 10)
                return `${amount.toLocaleString()} Rwf`;
            },
            sorter: (a: any, b: any) => Number(a?.amount) - Number(b?.amount),
            sortOrder: sortedInfo?.columnKey === 'amount' ? sortedInfo?.order : null,
            ellipsis: true
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text: any, record: any) => {
                const tagColorClass = record?.status === 'RECEIVED REQUEST' ? 'custom-tag-success' : 'custom-tag-error';

                return (
                    <Tag className={`custom-tag ${tagColorClass}`}>
                        {record?.status}
                    </Tag>
                );
            },
            key: 'status',
            width: 150,
            sorter: (a: any, b: any) => a.status.localeCompare(b.status),
            sortOrder: sortedInfo?.columnKey === 'status' ? sortedInfo?.order : null,
            ellipsis: true,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text: any, record: any) => (
                <div className="w-[60%] flex justify-center icon-container">
                    <i
                        className="far fa-eye cursor-pointer icon-style"
                        onClick={() => {
                            navigate('/view-refund-request', { state: record })
                        }}
                    ></i>
                </div>
            )
        }
    ]
    const bailiffsColumns: ColumnsType<DataType> = [
        {
            title: 'No.',
            dataIndex: 'index',
            key: 'index',
            width: 90,
            render: (text, record, index) => {
                const pageNumber = pagination.current || 1
                const pageSize = pagination.pageSize || 10
                const globalIndex = (pageNumber - 1) * pageSize + (index + 1)
                return <span>{globalIndex}</span>;
            },
            sorter: (a, b) => a.index - b.index,
            sortOrder: sortedInfo.columnKey === 'index' ? sortedInfo.order : null,
            ellipsis: false,
        },
        {
            title: 'Transaction Reference',
            dataIndex: 'transaction_reference',
            key: 'transaction_reference',
            width: 100,
            sorter: (a: any, b: any) => a?.transaction_reference.localeCompare(b?.transaction_reference),
            sortOrder: sortedInfo?.columnKey === 'transaction_reference' ? sortedInfo?.order : null,
            ellipsis: true,
            render: (text: string, record: any) => (
                <Tooltip
                    title={`
                Bank Code: ${record.bank_code}
                Debit Bank Name: ${record.debit_account_name}
                Debit Bank Number: ${record.debit_account_number}
                Reason: ${record.reason}
            `}>
                    <span>{text}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Beneficiary Account Name',
            dataIndex: 'beneficiary_account_name',
            key: 'beneficiary_account_name',
            width: 200,
            sorter: (a: any, b: any) => a?.beneficiary_account_name.localeCompare(b?.beneficiary_account_name),
            sortOrder: sortedInfo?.columnKey === 'beneficiary_account_name' ? sortedInfo?.order : null,
            ellipsis: true,
            render: (text: string, record: any) => (
                <Tooltip
                    title={`
                Bank Code: ${record.bank_code}
                Debit Bank Name: ${record.debit_account_name}
                Debit Bank Number: ${record.debit_account_number}
                Reason: ${record.reason}
            `}>
                    <span>{text}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Beneficiary Bank Name',
            dataIndex: 'beneficiary_bank_name',
            key: 'beneficiary_bank_name',
            width: 200,
            sorter: (a: any, b: any) => a?.beneficiary_bank_name.localeCompare(b?.beneficiary_bank_name),
            sortOrder: sortedInfo?.columnKey === 'beneficiary_bank_name' ? sortedInfo?.order : null,
            ellipsis: true,
            render: (text: string, record: any) => (
                <Tooltip
                    title={`
                Bank Code: ${record.bank_code}
                Debit Bank Name: ${record.debit_account_name}
                Debit Bank Number: ${record.debit_account_number}
                Reason: ${record.reason}
            `}>
                    <span>{text}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Beneficiary Account Number',
            dataIndex: 'beneficiary_account_number',
            key: 'beneficiary_account_number',
            width: 150,
            sorter: (a: any, b: any) => a?.beneficiary_account_number.localeCompare(b?.beneficiary_account_number),
            sortOrder: sortedInfo?.columnKey === 'beneficiary_account_number' ? sortedInfo?.order : null,
            ellipsis: true,
            render: (text: string, record: any) => (
                <Tooltip
                    title={`
                Bank Code: ${record.bank_code}
                Debit Bank Name: ${record.debit_account_name}
                Debit Bank Number: ${record.debit_account_number}
                Reason: ${record.reason}
            `}>
                    <span>{text}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 130,
            render: (_text: any, record: any) => {
                const amount = parseInt(record?.amount, 10)
                return amount.toLocaleString()
            },
            sorter: (a: any, b: any) => Number(a?.amount) - Number(b?.amount),
            sortOrder: sortedInfo?.columnKey === 'amount' ? sortedInfo?.order : null,
            ellipsis: true
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text: any, record: any) => {
                const tagColorClass = record?.status === 'RECEIVED REQUEST' ? 'custom-tag-success' : 'custom-tag-error';

                return (
                    <Tag className={`custom-tag ${tagColorClass}`}>
                        {record?.status}
                    </Tag>
                );
            },
            key: 'status',
            width: 150,
            sorter: (a: any, b: any) => a.status.localeCompare(b.status),
            sortOrder: sortedInfo?.columnKey === 'status' ? sortedInfo?.order : null,
            ellipsis: true
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text: any, record: any) => (
                <div className="w-[60%] flex justify-center icon-container">
                    <i
                        className="far fa-eye cursor-pointer icon-style"
                        onClick={() => {
                            navigate('/view-bailiff-refund-request', { state: record })
                        }}
                    ></i>
                </div>
            )
        }
    ]
    return (
      <div className="w-full">
          <div className="px-5 py-2.5 border-b-slate-200 border border-solid ">
              <div className="flex justify-between items-center">
                  <div className="text-xl font-bold flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#37762f"
                        viewBox="0 0 24 24"
                        stroke="none"
                        className="w-6 h-6 mr-2"
                      >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                      </svg>
                      <h2 className="titleHeadings">New Refund Request</h2>
                  </div>
                  <div className="md:w-1/5"></div>
              </div>
          </div>
          <div className="header-stat" style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'nowrap',
              overflowX: 'auto',
              gap: '20px'
          }}>
              <div className="stat-item" style={{
                  backgroundColor: '#f4f7fb',
                  padding: '10px 20px',
                  borderRadius: '8px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  flex: '1 1 20%',
                  textAlign: 'center',
                  fontFamily: 'Arial, sans-serif',
                  fontSize: '14px',
                  color: '#333'
              }}>
                  <strong> Total Amount Requested: </strong>
                  <strong>
                      {
                          ((Number(BailiffAmountRequestedToBeRefunded) ?? 0) +
                            (Number(BiddersAmountRequestedToBeRefund) ?? 0)).toLocaleString()
                      } Rwf
                  </strong>
              </div>

              <div className="stat-item" style={{
                  backgroundColor: '#e0f7fa',
                  padding: '10px 20px',
                  borderRadius: '8px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  flex: '1 1 20%',
                  textAlign: 'center',
                  fontFamily: 'Arial, sans-serif',
                  fontSize: '14px',
                  color: '#333'
              }}>
                  <strong>New Amount Requested: </strong>
                  <strong>
                      {
                          ((Number(NewBiddersAmountRequestedToBeRefunded) ?? 0) +
                            (Number(NewBailiffAmountRequestedToBeRefunded) ?? 0)).toLocaleString()
                      } Rwf
                  </strong>
              </div>

              <div className="stat-item" style={{
                  backgroundColor: '#fff3e0',
                  padding: '10px 20px',
                  borderRadius: '8px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  flex: '1 1 20%',
                  textAlign: 'center',
                  fontFamily: 'Arial, sans-serif',
                  fontSize: '14px',
                  color: '#333'
              }}>
                  <strong>Remain to
                      Request: </strong>
                  <strong>
                      {(Number(TotalPayments) - ((Number(BiddersAmountRequestedToBeRefund) +
                        Number(BailiffAmountRequestedToBeRefunded)))).toLocaleString()} Rwf
                  </strong>
              </div>

              <div className="stat-item" style={{
                  backgroundColor: '#e8f5e9',
                  padding: '10px 20px',
                  borderRadius: '8px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  flex: '1 1 20%',
                  textAlign: 'center',
                  fontFamily: 'Arial, sans-serif',
                  fontSize: '14px',
                  color: '#333'
              }}>
                  <strong>Total Paid Amount: </strong>
                  <strong>
                      {(Number(TotalPayments)).toLocaleString()} Rwf
                  </strong>
              </div>

              <div className="stat-item" style={{
                  backgroundColor: '#fff9c4',
                  padding: '10px 20px',
                  borderRadius: '8px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  flex: '1 1 20%',
                  textAlign: 'center',
                  fontFamily: 'Arial, sans-serif',
                  fontSize: '14px',
                  color: '#333'
              }}>
                  <strong>Total refunded Amount: </strong>
                  <strong>
                      {(((Number(BiddersAmountRequestedToBeRefund) + Number(BailiffAmountRequestedToBeRefunded))) - ((Number(NewBiddersAmountRequestedToBeRefunded)) + (Number(NewBailiffAmountRequestedToBeRefunded)))).toLocaleString()} Rwf
                  </strong>
              </div>
          </div>

          <div className="px-7">
              <div className="py-4">
                  <Form
                    name="normal_login"
                    layout="vertical"
                    className="login-form p-10"
                    initialValues={{ remember: true }}
                    form={form}
                  >
                      <Card className="bg-slate-100">
                          <Row gutter={16}>
                              <Col xs={24} xl={18}>
                                  <Form.Item name="range_picker">
                                      <RangePicker
                                        size="large"
                                        className="w-full"
                                        allowClear
                                        onChange={(value, valueString) => {
                                            setFromDate(valueString[0])
                                            setToDate(valueString[1])
                                        }}
                                      />
                                  </Form.Item>
                                  {filter !== ''
                                    ? (
                                      <div className="flex justify-between pb-2.5 md:pb-0">
                                          <div>
                                              Applied filters: <span
                                            className="font-medium">{filter}</span>
                                          </div>
                                          <div
                                            className="bg-black text-center py-0.5 px-2.5
                   text-white rounded-sm font-medium cursor-pointer"
                                            onClick={clearFilter}
                                          >
                                              Clear X
                                          </div>
                                      </div>
                                    )
                                    : (
                                      ''
                                    )}
                              </Col>
                              <Col xs={24} xl={6}>
                                  <Form.Item>
                                      <Button
                                        type="primary"
                                        size="large"
                                        className="w-full font-bold"
                                        onClick={handleDownloadExcel}
                                        disabled={fromDate.length === 0 || toDate.length === 0}
                                      >
                                          <div className="float-left">
                                              <i className="fa-file-excel"></i>
                                          </div>
                                          DOWNLOAD
                                      </Button>
                                  </Form.Item>
                              </Col>
                          </Row>
                      </Card>
                  </Form>
              </div>
              <Card title={<span style={{
                  backgroundColor: '#37762f',
                  color: 'white',
                  padding: '5px 10px'
              }}>Bailiffs & Bidders Refund Request</span>}
                    style={{ marginTop: '12px' }}
                    headStyle={{ backgroundColor: '#37762f' }}>
                  <Tabs
                    onChange={(key) => handleTabChange(key)}
                    type="card"
                    size="large"
                    defaultActiveKey="bidders"
                    tabBarExtraContent={(
                      <div style={{
                          display: 'flex',
                          alignItems: 'center'
                      }}>
                          {/* <ZoomOutOutlined /> */}
                          <Input
                            size="large"
                            placeholder="Quick search in the tables"
                            prefix={<ZoomOutOutlined/>}
                            onChange={OnRefundRequestSearch}
                            style={{
                                width: '400px',
                                marginRight: '8px'
                            }}
                          />
                          <Button style={{ marginRight: '12px' }} onClick={handleDownloadExcelFile}>
                              Download Report
                          </Button>
                          <Tag color="#108ee9">Selected {filter}</Tag>
                          <Button
                            type="link"
                            onClick={() => {
                                clearFilter()
                            }}
                          >
                              Clear Filter
                          </Button>
                      </div>
                    )}
                  >
                      <Tabs.TabPane tab="Bidders" key="bidders">
                          <Spin spinning={loading} indicator={antIcon} tip="Loading...">
                              <Table
                                columns={biddersColumns}
                                dataSource={refundRequestList}
                                onChange={handleTableChange}
                                pagination={pagination}
                                loading={loading}
                              />
                          </Spin>
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Bailiffs" key="bailiffs">
                          <Spin spinning={loading} indicator={antIcon} tip="Loading...">
                              <Table
                                columns={bailiffsColumns}
                                dataSource={bailiffRefundRequestList}
                                onChange={handleTableChange}
                                pagination={pagination}
                                loading={loading}
                              />
                          </Spin>
                      </Tabs.TabPane>
                  </Tabs>
              </Card>
          </div>
      </div>
    );
};

export default BiddersRefundRequest;
