import React, { useEffect, useState } from 'react'
import {
  Button,
  DatePicker,
  Form,
  Spin,
  Table,
  Tabs,
  message,
  Select
} from 'antd'
import { useNavigate } from 'react-router-dom'
import InstitutionUserService from '../../services/InstitutionUserService'
import TabPane = Tabs.TabPane
import { LoadingOutlined, BarChartOutlined } from '@ant-design/icons'
import { saveAs } from 'file-saver'
import { jsPDF } from 'jspdf'
import * as XLSX from 'xlsx'
import moment from 'moment'

const { RangePicker } = DatePicker
interface DataType {
  key: string | number
  index: number
  bank_code: string
  transaction_reference: string
  debit_account_number: string
  debit_account_name: string
  beneficiary_account_name: string
  beneficiary_bank_name: string
  beneficiary_account_number: string
  transaction_currency: string
  amount: number
  reason: string
  status: string
  created_at: string
  updated_at: string
}

const Reports: React.FC = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 })
  const [SearchWithParameters, setSearchWithParameters] = useState<DataType[]>([])
  const [bailiffRefundRequestList, setBailiffRefundRequestList] = useState<DataType[]>([])
  const [activeTab, setActiveTab] = useState('bidders')
  const [filterStatus, setFilterStatus] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [totals, setTotals] = useState<any>(null)
  const [count, setCount] = useState(0)

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const downloadCSV = (data: DataType[]) => {
    const csvData = data.map((item) => ({
      ...item,
      amount: item.amount.toLocaleString() // You may want to format the amount
    }))
    const csv = csvData.map((row) => Object.values(row).join(',')).join('\n')
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' })
    saveAs(blob, 'refunds_report.csv')
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const downloadExcelFile = (data: DataType[], filename: string) => {
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Refunds')
    XLSX.writeFile(wb, filename)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  // const downloadPDF = (data: DataType[]) => {
  //   // eslint-disable-next-line new-cap
  //   const doc = new jsPDF()
  //   let yPosition = 10
  //   data.forEach((item) => {
  //     doc.text(`${item.index}. ${item.transaction_reference}`, 10, yPosition)
  //     yPosition += 10
  //     doc.text(`Amount: ${item.amount.toLocaleString()} Rwf`, 10, yPosition)
  //     yPosition += 10
  //   })
  //   doc.save('refunds_report.pdf')
  // }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const downloadPDF = (data: DataType[]) => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF('landscape')

    // Set up the font and margin
    doc.setFont('Arial', 'normal')
    const marginLeft = 10
    const pageWidth = doc.internal.pageSize.width
    let yPosition = 20

    const logoImagePath = 'assets/img/logo3.png'

    const img = new Image()
    img.src = logoImagePath
    img.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = img.width
      canvas.height = img.height
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      ctx.drawImage(img, 0, 0)
      const logoBase64 = canvas.toDataURL('image/png')

      doc.addImage(logoBase64, 'PNG', marginLeft, 10, 40, 20)
    }

    yPosition += 30

    const currentDate = new Date().toLocaleDateString()
    doc.setFontSize(12)

    doc.text(`REFUND REPORT OF 5 % OF BID SECURITY - ${currentDate}`, marginLeft, yPosition)

    yPosition += 10
    doc.line(marginLeft, yPosition, pageWidth - marginLeft, yPosition)

    yPosition += 5
    //
    yPosition += 5

    doc.setFillColor(0, 123, 255)
    doc.rect(marginLeft - 5, yPosition - 8, pageWidth - 20, 10, 'F')

    doc.setTextColor(255, 255, 255)

    doc.text('No', marginLeft, yPosition)
    doc.text('Account Name', marginLeft + 20, yPosition)
    doc.text('Bank Name', marginLeft + 70, yPosition)
    doc.text('Account Number', marginLeft + 120, yPosition)
    doc.text('Reference', marginLeft + 170, yPosition)
    doc.text('Amount', marginLeft + 200, yPosition)
    doc.text('Status', marginLeft + 245, yPosition)

    doc.setTextColor(0, 0, 0)

    yPosition += 10
    doc.line(marginLeft, yPosition, pageWidth - marginLeft, yPosition) // Draw line

    data.forEach(item => {
      yPosition += 10
      doc.text(String(item.index), marginLeft, yPosition)
      doc.text(item.beneficiary_account_name, marginLeft + 20, yPosition)
      doc.text(item.beneficiary_bank_name, marginLeft + 70, yPosition)
      doc.text(item.beneficiary_account_number, marginLeft + 120, yPosition)
      doc.text(item.transaction_reference, marginLeft + 170, yPosition)
      doc.text(item.amount.toLocaleString(), marginLeft + 200, yPosition)
      doc.text(item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase(), marginLeft + 245, yPosition)
    })

    // Save the PDF
    doc.save('refunds_report.pdf')
  }

  interface FiltersProps {
    form: any
    onSearch: (values: any) => void
    onClear: () => void
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  useEffect(() => {
    const filters = { from: fromDate, to: toDate, status: filterStatus }
    void fetchRefundRequests(filters)
  }, [pagination.current, pagination.pageSize, fromDate, toDate, filterStatus])

  // Fetch and calculate totals, filtering, etc.
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const fetchRefundRequests = async (filters: any) => {
    setLoading(true)
    const { from, to, status } = filters

    const fetchMethod =
      activeTab === 'bidders'
        ? InstitutionUserService.SearchWithParameters
        : InstitutionUserService.GetBailiffRefundRequestList

    const response = await fetchMethod(pagination.pageSize, pagination.current, from, to, status)
    setLoading(false)

    if (response?.status === 200) {
      const mappedData = response.data.map((item: any, index: number) => ({
        key: item.refund_request_id ?? index,
        index: index + 1,
        bank_code: item.bank_code ?? 'N/A',
        transaction_reference: item.transaction_reference ?? 'N/A',
        debit_account_number: item.debit_account_number ?? 'N/A',
        debit_account_name: item.debit_account_name ?? 'N/A',
        beneficiary_account_name: item.beneficiary_account_name ?? 'N/A',
        beneficiary_bank_name: item.beneficiary_bank_name ?? 'N/A',
        beneficiary_account_number: item.beneficiary_account_number ?? 'N/A',
        transaction_currency: item.transaction_currency ?? 'RWF',
        amount: (typeof item.amount === 'string' && !isNaN(parseFloat(item.amount)))
          ? parseFloat(item.amount)
          : 0,
        reason: item.reason ?? 'No reason provided',
        status: item.status ?? 'Unknown',
        created_at: item.created_at ?? 'N/A',
        updated_at: item.updated_at ?? 'N/A',
        phone_number: item.phone_number ?? 'N/A',
        email: item.email ?? 'N/A',
        link: item.link ?? 'N/A'
      }))

      if (activeTab === 'bidders') {
        setSearchWithParameters(mappedData)
      } else {
        setBailiffRefundRequestList(mappedData)
      }

      setPagination((prev) => ({
        ...prev,
        total: response.meta?.total ?? 0
      }))
      setCount(response.meta?.total)
      setTotals({
        requestedAmount: isNaN(Number(response.meta?.totals?.requestedAmount)) ? 0 : Number(response.meta?.totals?.requestedAmount),
        refundedAmount: isNaN(Number(response.meta?.totals?.refundedAmount)) ? 0 : Number(response.meta?.totals?.refundedAmount),
        pendingAmount: isNaN(Number(response.meta?.totals?.pendingAmount)) ? 0 : Number(response.meta?.totals?.pendingAmount),
        publicTresuryAmount: isNaN(Number(response.meta?.totals?.publicTresuryAmount)) ? 0 : Number(response.meta?.totals?.publicTresuryAmount)
      })
    } else {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      void message.error(`Failed to fetch refund requests: ${response?.statusText ?? 'Unknown error'}`)
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const calculateTotalAmount = () => {
    let total = 0
    const source =
      activeTab === 'bidders' ? SearchWithParameters : bailiffRefundRequestList
    total = source.reduce((sum, record) => sum + record.amount, 0)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleTabChange = (key: string) => { setActiveTab(key) }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const clearFilters = () => {
    form.resetFields() // Reset the form fields
    setFromDate('') // Reset the date range
    setToDate('')
    setFilterStatus('') // Reset the status filter
    setPagination({ ...pagination, current: 1 }) // Reset pagination to first page
    // Refetch with cleared filters
    void fetchRefundRequests({ from: '', to: '', status: '' })
  }

  const handleFilterSearch = (values: any): void => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { range_picker, filterStatus } = values

    // const from = '2023-01-01'
    // const to = '2025-01-01'

    let from = ''
    let to = ''

    if (Array.isArray(range_picker) && range_picker.length === 2) {
      from = range_picker[0].format('YYYY-MM-DD')
      to = range_picker[1].format('YYYY-MM-DD')
    }

    setFromDate(from)
    setToDate(to)
    setFilterStatus(filterStatus)

    setPagination({ ...pagination, current: 1 })
    void fetchRefundRequests({ from, to, status: filterStatus })
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const TableColumns = () => [
    {
      title: 'No.',
      dataIndex: 'index',
      render: (_: any, __: any, index: number) => index + 1
    },
    {
      title: 'Reference Number',
      dataIndex: 'transaction_reference'
    },
    {
      title: 'Beneficiary Name',
      dataIndex: 'beneficiary_account_name',
      render: (text: string) => text.toUpperCase()
    },
    {
      title: 'Beneficiary Bank Name',
      dataIndex: 'beneficiary_bank_name',
      render: (text: string) => text.toUpperCase()
    },
    {
      title: 'Beneficiary Account Number',
      dataIndex: 'beneficiary_account_number'
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      render: (value: { toLocaleString: () => any }) => `${value.toLocaleString()} Rwf`
    },
    {
      title: 'Status',
      dataIndex: 'status',
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      render: (value: { toLocaleString: () => any }) => `${value.toLocaleString()}`
    }
  ]

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <div className="w-full" style={{ width: '1200px' }}>
      <div className="px-5 py-2.5 border-b-slate-200 border border-solid ">
        <div className="header-card">
          <div className="flex justify-between items-center">
            <div className="text-xl font-bold flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="#37762f" viewBox="0 0 24 24"
                   stroke="none" className="w-6 h-6 mr-2">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                      d="M19 9l-7 7-7-7"/>
              </svg>
              <h2 className="titleHeadings">Refund Request Report</h2>
            </div>
            <div className="md:w-1/5"></div>
          </div>
        </div>
      </div>

      {/* Statistics Section */}
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <h2
        className="titleHeadings"
        style={{
          marginLeft: '15px',
          marginTop: '20px',
          fontWeight: 'bolder',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <BarChartOutlined
          style={{
            marginRight: '10px',
            fontSize: '18px',
            color: '#37762f'
          }}
        />
        Statistics Section:
      </h2>
      {/* Stat items go here */}

      <div className="header-stat" style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        gap: '20px'
      }}>
        {/* Total Requested Amount */}
        <div className="stat-item" style={{
          backgroundColor: '#f4f7fb',
          padding: '10px 20px',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          flex: '1 1 20%',
          textAlign: 'center',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          color: '#333'
        }}>
          <strong>Total Amount Requested: </strong>
          <strong>
            {(totals !== null && totals !== undefined)
              ? (Number(totals.requestedAmount) +
                Number(totals.pendingAmount) +
                Number(totals.refundedAmount) +
                Number(totals.publicTresuryAmount)).toLocaleString()
              : 'Loading...'}
            Rwf
          </strong>
        </div>

        {/* New Amount Requested */}
        <div className="stat-item" style={{
          backgroundColor: '#e0f7fa',
          padding: '10px 20px',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          flex: '1 1 20%',
          textAlign: 'center',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          color: '#333'
        }}>
          <strong>New Amount Requested: </strong>
          <strong>
            {(totals !== null && totals !== undefined)
              ? (Number(totals.requestedAmount)).toLocaleString()
              : 'Loading...'}
            Rwf
          </strong>
        </div>

        {/* Total Pending Requests */}
        <div className="stat-item" style={{
          backgroundColor: '#fff3e0',
          padding: '10px 20px',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          flex: '1 1 20%',
          textAlign: 'center',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          color: '#333'
        }}>
          <strong>Total Pending Request: </strong>
          <strong>
            {(totals !== null && totals !== undefined)
              ? (Number(totals.pendingAmount)).toLocaleString()
              : 'Loading...'}
            Rwf
          </strong>
        </div>

        {/* Total Refunded Amount */}
        <div className="stat-item" style={{
          backgroundColor: '#e8f5e9',
          padding: '10px 20px',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          flex: '1 1 20%',
          textAlign: 'center',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          color: '#333'
        }}>
          <strong>Total Refunded Amount: </strong>
          <strong>
            {(totals !== null && totals !== undefined)
              ? (Number(totals.refundedAmount)).toLocaleString()
              : 'Loading...'}
            Rwf
          </strong>
        </div>

        {/* Total Amount to Public Treasury */}
        <div className="stat-item" style={{
          backgroundColor: '#fff9c4',
          padding: '10px 20px',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          flex: '1 1 20%',
          textAlign: 'center',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          color: '#333'
        }}>
          <strong>Total Amount to Public Treasury: </strong>
          <strong>
            {(totals !== null && totals !== undefined)
              ? (Number(totals.publicTresuryAmount)).toLocaleString()
              : 'Loading...'}
            Rwf
          </strong>
        </div>
      </div>

      {/* Filters Section */}
      <div style={{
        marginLeft: '20px',
        marginBottom: '20px'
      }}>
        <fieldset
          style={{
            width: '100%',
            border: '1px solid #d9d9d9',
            padding: '16px',
            height: '100px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflow: 'hidden'
          }}
        >
          <legend style={{
            fontWeight: 'bold',
            fontSize: '14px'
          }}> Search Filters
          </legend>
          <Form form={form} layout="inline" onFinish={handleFilterSearch} style={{ width: '100%' }}>
            {/* <Form.Item name="range_picker"> */}
            {/*   <DatePicker.RangePicker style={{ width: 450 }}/> */}
            {/* </Form.Item> */}
            <Form.Item name="range_picker">
              <RangePicker
                size="large"
                className="w-full"
                allowClear
                style={{ width: '450px' }}
                onChange={(value, valueString) => {
                  setFromDate(valueString[0])
                  setToDate(valueString[1])
                }}
              />
            </Form.Item>
            <Form.Item name="filterStatus" label="Status">
              <Select
                placeholder="Select Status"
                allowClear
                size="middle"
                style={{ width: 250 }}
                onChange={(value) => {
                  // Handle the selected value
                  setFilterStatus(value)
                }}
              >
                <Select.Option value="Downloaded to be Refunded">Downloaded to be
                  Refunded</Select.Option>
                <Select.Option value="RECEIVED REQUEST">Received Request</Select.Option>
                <Select.Option value="PENDING">Pending</Select.Option>
                <Select.Option value="PUBLIC TREASURY">Public Treasury</Select.Option>
                <Select.Option value="REFUNDED">Refunded</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <Button type="primary" htmlType="submit">
                Search
              </Button>
              <Button onClick={clearFilters} style={{ marginLeft: '10px' }}>
                Clear
              </Button>
            </Form.Item>
          </Form>
        </fieldset>
      </div>

      {/* Download Buttons */}
      <div
        className="actions"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginLeft: '20px',
          marginBottom: '20px'
        }}
      >
        {/* Total Number on the Left */}
        <div
          style={{
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#333',
            marginRight: '20px'
          }}
        >
          <strong>Total: </strong>
          <span>
      {Number(count)}
    </span>
        </div>

        {/* Button Group */}
        <div style={{
          display: 'flex',
          gap: '10px'
        }}>
          <Button
            onClick={() => {
              downloadCSV(
                activeTab === 'bidders' ? SearchWithParameters : bailiffRefundRequestList
              )
            }}
            style={{
              backgroundColor: '#4CAF50',
              color: 'white'
            }}
          >
            Download CSV
          </Button>
          <Button
            onClick={() => {
              downloadExcelFile(
                activeTab === 'bidders' ? SearchWithParameters : bailiffRefundRequestList,
                'Refunds Report.xlsx'
              )
            }}
            style={{
              backgroundColor: '#008CBA',
              color: 'white'
            }}
          >
            Download Excel
          </Button>
          <Button
            onClick={() => {
              downloadPDF(
                activeTab === 'bidders' ? SearchWithParameters : bailiffRefundRequestList
              )
            }}
            style={{
              backgroundColor: '#f44336',
              color: 'white'
            }}
          >
            Download PDF
          </Button>
        </div>
      </div>

      {/* Tabs Section */}
      <div style={{ marginLeft: '20px' }}>
        <Tabs
          defaultActiveKey="bidders"
          onChange={handleTabChange}
          tabBarStyle={{
            backgroundColor: '#37762f',
            color: '#ffffff',
            fontWeight: 'bold'
          }}
        >
          <TabPane tab="Bidders Requests" key="bidders">
            <Spin spinning={loading} indicator={<LoadingOutlined spin/>}>
              <Table
                columns={TableColumns()}
                dataSource={SearchWithParameters}
                rowKey="transaction_reference"
                pagination={pagination}
                style={{ width: '150%' }}
              />
            </Spin>
          </TabPane>
          <TabPane tab="Bailiffs Requests" key="bailiffs">
            <Spin spinning={loading} indicator={<LoadingOutlined spin/>}>
              <Table
                columns={TableColumns()}
                dataSource={bailiffRefundRequestList}
                rowKey="transaction_reference"
                pagination={pagination}
                style={{ width: '150%' }}
              />
            </Spin>
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default Reports
