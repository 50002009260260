import React, { useEffect, useState } from 'react'
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Space,
  Progress,
  Checkbox,
  Upload, message, Modal, Alert
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import minijust_logo from '../../assets/img/minijust_logo.png'
import { PHONE_PATTERN } from '../../constants/general'
import InstitutionUserService from '../../services/InstitutionUserService'
import { useParams, useLocation } from 'react-router-dom'
import moment from 'moment'
import { type UploadChangeParam } from 'antd/es/upload'

const BaillifRefundRequest: React.FC = () => {
  const [form] = Form.useForm()
  const [currentStep, setCurrentStep] = useState(1)
  const [fullName, setBidderFullName] = useState('')
  const [phone_number, setPhoneNumber] = useState('')
  const [payment_date, setPaymentDate] = useState('')
  const [loadingRefund, setLoadingRefund] = useState(false)
  const [declarationChecked, setDeclarationChecked] = useState(false)
  const { ref_number } = useParams()
  const [codeBank, setBankCode] = useState()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [showOTPForm, setShowOTPForm] = useState(false)
  const [countdown, setCountdown] = useState(300000)
  const [isFormValidated, setIsFormValidated] = useState(true)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [transactionReference, setTransactionReference] = useState(false)
  const [bidderBidReferenceNumber, setReferenceNumber] = useState<string>('')
  const [showBankTransferChargeModal, setShowBankTransferChargeModal] = useState(false)
  const [otpValue, setOtpValue] = useState('')
  const [token, setToken] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [showDeclarationAlert, setShowDeclarationAlert] = useState(false)
  const [showWarningAlert, setShowWarningAlert] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [showWarningAlert2, setShowWarningAlert2] = useState(false)
  const [targetPaymentDateValue, setTargetPaymentDateValue] = useState(false)
  const [showCancelMessage, setShowCancelMessage] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [paymentAmount, setPaymentAmount] = useState('')
  const [bailiffId, setBailiffId] = useState('')
  const [refundableAmount, setRefundableAmount] = useState('0')
  const [showBankCodeModal, setShowBankCodeModal] = useState(false)
  const [showHello, setShowHello] = useState(false)
  const [fileList, setFileList] = useState<any[]>([])
  const [isVisible, setIsVisible] = useState(true)
  const steps = [
    { title: 'Details Verification' },
    { title: 'Provide Payment Details' },
    { title: 'Final Step' }
  ]
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleAgree = () => {
    setShowBankTransferChargeModal(false)
    setShowForm(true)
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const nextStep = () => {
    setCurrentStep(currentStep + 1)
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const prevStep = () => {
    setCurrentStep(currentStep - 1)
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleFileChange = (info: UploadChangeParam<any>) => {
    // eslint-disable-next-line no-console
    console.log('File changed:', info.file)
    setFileList(info.fileList)
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtpValue(e.target.value)
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleOTPFormCancel = () => {
    setShowOTPForm(false)
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false)
    }, 3000)

    return () => { clearTimeout(timer) }
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHello(true)
    }, 3000)
    return () => { clearTimeout(timer) }
  }, [])

  useEffect(() => {
    // eslint-disable-next-line no-console
  }, [ref_number])
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleOTPSubmit = () => {
    const otp = otpValue
    InstitutionUserService.getBailiffLastInsertedData()
      .then((res: any) => {
        const verifyData = res.data[0]
        setTransactionReference(verifyData.transaction_reference)
        const payloadForVerifyOTP = {
          transaction_reference: verifyData.transaction_reference,
          otp
        }
        const confirmationPayload = {
          transaction_reference: verifyData.transaction_reference,
          status: 'RECEIVED REQUEST'
        }
        InstitutionUserService.bailiffVerifyOtpToSendRequest(payloadForVerifyOTP)
          .then((respo: any) => {
            if (respo.status === 200) {
              setShowOTPForm(false)
              InstitutionUserService.BailiffRefundRequestConfirmation(confirmationPayload)
                .then((confRespo: any) => {
                  if (confRespo.status === 200) {
                    setShowSuccessAlert(true)
                    // eslint-disable-next-line max-len
                    // void message.success('s Submitted. Waiting for being Refunded...')
                  } else {
                    setShowBankTransferChargeModal(false)
                    // void message.error('Failed To Load Data. Contact Us on iecms-support@minijust.gov.rw')
                    setShowCancelMessage(false)
                    // void message.warning('Failed To Submit Your Request. Contact Us on iecms-support@minijust.gov.rw')
                  }
                })
                .catch((error: any) => {
                  if (error.response.status === 400) {
                    void message.warning('Request Failed to be Submitted')
                  }
                })
            }
          })
          .catch((error: any) => {
            if (error.response.status === 400) {
              // Handle error response
              void message.warning('Failed To Verify OTP')
            }
          })
      })
      // eslint-disable-next-line n/handle-callback-err
      .catch((error: any) => {
        setShowBankTransferChargeModal(false)
        // void message.error('Failed To Load Data. Contact Us on iecms-support@minijust.gov.rw')
        setShowCancelMessage(false)
      })
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHello(true)
    }, 20000) // 20 seconds
    return () => { clearTimeout(timer) }
  }, [])
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          clearInterval(timer)
          return 0
        }
        return prevCountdown - 1000
      })
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchData = async () => {
      setLoading(true)
      if (ref_number !== null && ref_number !== undefined) {
        try {
          const params = new URLSearchParams(location.search)
          const prpParam = params.get('prp')
          // eslint-disable-next-line max-len
          const resp = await InstitutionUserService.validateBailiffDetailsOnAuction(prpParam, ref_number)
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          const data = resp.data
          setReferenceNumber(data?.bid?.bidSecurityRefNumber)
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (resp && resp.status === 200) {
            form.setFieldsValue({
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              bidder_full_name: `${data?.bidder?.firstName} ${data?.bidder?.lastName}`,
              bidder_national_id_number: data?.bidder?.idNumberTin,
              phone_number: data?.bidder?.telephone,
              // eslint-disable-next-line max-len,@typescript-eslint/restrict-template-expressions
              bailiff_assigned: `${data?.bailiff?.bailiffFirstName} ${data?.bailiff?.bailiffLastName}`,
              bailiff_national_id_number: data?.bailiff?.bailiffNationalId,
              bailiff_phone_number: data?.bailiff?.bailiffPhoneNumber,
              property_id: data?.bid?.propertyId,
              bidSecurityReference: data?.bid?.bidSecurityRefNumber,
              prp: prpParam,
              execution_case_number: data?.bid?.executionCaseNumber,
              beneficiary_account_number: data?.bailiff?.bailiffBankAccountNumber,
              beneficiary_bank_name: data?.bailiff?.bailiffBankName,
              beneficiary_bank_account_name: data?.bailiff?.bailiffBankAccountName,
              bank_code: data?.bailiff?.bailiffBankCode
            })
            // eslint-disable-next-line @typescript-eslint/no-shadow,max-len
            void InstitutionUserService.loadWinnerBidderDetailsForRefund(bidderBidReferenceNumber).then((res: any) => {
              // eslint-disable-next-line @typescript-eslint/no-shadow
              const data = res.data
              let amountValue = parseInt(String(data?.payment.amount)).toLocaleString()
              if (codeBank !== '040') {
                amountValue = (parseInt(String(data?.payment.amount)) - 1000).toLocaleString()
              }
              form.setFieldsValue({
                // transaction_reference: data.ref_number,
                amount: parseInt(String(data?.payment.amount)).toLocaleString(),
                transaction_currency: data?.payment.currency,
                property_id: data.bid?.auction.property_id,
                bidder_national_id_number: data?.bid?.bidder.nid,
                bidder_id: data?.bid?.bidder.bidder_id,
                bid_security_id: data?.payment.bid_security_id,
                bid_payment_date: data?.payment.payment_date,
                refundable_bid_security_amount: amountValue
              })
              setPaymentDate(data?.payment.payment_date)
              setPaymentAmount(data?.payment.amount)
            })
          } else {
            // eslint-disable-next-line no-console
            console.error('Failed To Fetch Data. Server Returned Status:', resp.status)
            void message.error('Failed To Fetch Data. Server returned status:', resp.status)
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Unhandled error:', error)
          setShowBankTransferChargeModal(false)
          // void message.error('Failed To Load Data. Contact Us on iecms-support@minijust.gov.rw')
          setShowCancelMessage(false)
        } finally {
          setLoading(false)
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('Ref number is null or undefined')
        setShowBankTransferChargeModal(false)
        void message.error('Failed To Load Data. Reference number is missing.')
        setShowCancelMessage(false)
      }
    }

    void fetchData()
  }, [ref_number, form, location.search, codeBank, bidderBidReferenceNumber])

  useEffect(() => {
    const bankCode = form.getFieldValue('bank_code')
    setBankCode(bankCode)
    // eslint-disable-next-line no-console
    if (bankCode !== '040') {
      if (bankCode !== null && bankCode !== undefined && bankCode !== '') {
        setShowBankTransferChargeModal(true)
        setShowBankCodeModal(false)
        setShowCancelMessage(false)
        const bailiffRefundableAmount = Number(paymentAmount) - 1000
        setRefundableAmount(bailiffRefundableAmount.toString())
      } else {
        setShowBankTransferChargeModal(false)
        setShowBankCodeModal(true)
      }
    } else {
      if (bankCode !== null && bankCode !== undefined && bankCode !== '') {
        setShowBankTransferChargeModal(true)
        setShowBankCodeModal(false)
        setShowCancelMessage(false)
        setRefundableAmount(paymentAmount)
      } else {
        setShowBankTransferChargeModal(false)
        setShowBankCodeModal(true)
      }
    }
  },
  [form, paymentAmount])

  const onFinish = (values: any): void => {
    setLoadingRefund(true)
    setIsFormSubmitted(true)
    const paymentDate = moment(payment_date, 'YYYY-MM-DD HH:mm:ss')
    // eslint-disable-next-line no-console
    // console.log('Payment Date:', paymentDate)
    const targetPaymentDate = moment('2024-07-25', 'YYYY-MM-DD')
    const modifiedTargetPaymentDate = targetPaymentDate.toLocaleString()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setTargetPaymentDateValue(modifiedTargetPaymentDate)
    if (!paymentDate.isValid()) {
      setLoadingRefund(false)
      // eslint-disable-next-line max-len
      void message.warning(`Invalid payment date format2: ${paymentDate.format('YYYY-MM-DD HH:mm:ss')}.`)
      return
    }
    if (!paymentDate.isSameOrAfter(targetPaymentDate)) {
      setLoadingRefund(false)
      setShowWarningAlert2(true)
      // eslint-disable-next-line max-len
      // void message.warning(`Refund can only be triggered for payments starting from ${modifiedTargetPaymentDate}. Any Assistance Please contact us on iecms-support@minijust.gov.rw`)
      return
    }
    const validateOtpExpirationPayload = {
      transaction_reference: transactionReference,
      status: 'PENDING'
    }

    if (!declarationChecked) {
      setLoadingRefund(false)
      // eslint-disable-next-line max-len
      // void message.warning('Please confirm the declaration part to be able to submit your Request.')
      setShowDeclarationAlert(true)
      return
    }
    form
      .validateFields()
      .then(() => {
        setIsFormValidated(true)
        // setShowOTPForm(true)
      })
      .catch(() => {
        setIsFormValidated(false)
        setLoadingRefund(false)
      })
    void InstitutionUserService.bailiffValidateRequestWhenOtpExpired(validateOtpExpirationPayload)
    const formData = new FormData()
    formData.append('file', fileList[0]?.originFileObj)
    const executionCaseNumber = form.getFieldValue('execution_case_number')
    const bailiffAssignee = form.getFieldValue('bailiff_assigned')
    const bailiffNationalId = form.getFieldValue('bailiff_national_id_number')
    const bailiffPhoneNumber = form.getFieldValue('bailiff_phone_number')
    const bidderPhoneNumber = form.getFieldValue('phone_number')
    const propertyId = form.getFieldValue('property_id')
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const bidSecurityReference = form.getFieldValue('bidSecurityReference')
    const amount = form.getFieldValue('amount')
    const cleanedAmount = amount.replace(/,/g, '')
    const transactionCurrency = form.getFieldValue('transaction_currency')
    const beneficiaryAccountNumber = form.getFieldValue('beneficiary_account_number')
    const beneficiaryBankName = form.getFieldValue('beneficiary_bank_name')
    const beneficiaryBankAccountName = form.getFieldValue('beneficiary_bank_account_name')
    const bankCode = form.getFieldValue('bank_code')
    const bidderId = form.getFieldValue('bidder_id')
    const bidSecurityId = form.getFieldValue('bid_security_id')

    formData.append('file', fileList[0]?.originFileObj)
    formData.append('bailiff_assignee', bailiffAssignee)
    formData.append('bailiff_national_id', bailiffNationalId)
    formData.append('bailiff_phone_number', bailiffPhoneNumber)
    formData.append('property_id', propertyId)
    formData.append('execution_case_number', executionCaseNumber)
    formData.append('bank_code', bankCode)
    formData.append('transaction_reference', bidSecurityReference)
    formData.append('debit_account_number', '000400696575429')
    formData.append('debit_account_name', 'MINIJUST AUCTION FUNDS MAF')
    formData.append('beneficiary_name', bailiffAssignee)
    formData.append('beneficiary_account_name', beneficiaryBankAccountName)
    formData.append('beneficiary_bank_name', beneficiaryBankName)
    formData.append('beneficiary_account_number', beneficiaryAccountNumber)
    formData.append('amount', cleanedAmount)
    formData.append('transaction_currency', transactionCurrency)
    formData.append(
      'reason',
      // eslint-disable-next-line max-len,@typescript-eslint/restrict-template-expressions
      `Refund of ${cleanedAmount} for ${bailiffAssignee} ${bailiffNationalId}, ${bailiffPhoneNumber} ${executionCaseNumber} with ${propertyId}`
    )
    // formData.append('reason', reason: `Refund of ${values.amount} for ${values.beneficiary_account_name}:(${values.bidder_national_id_number}, ${values.phone_number}), ${values.execution_case_number} - ${values.property_id}`,)
    formData.append('status', 'PENDING')
    formData.append('bidder_id', bidderId)
    formData.append('bid_security_id', bidSecurityId)
    formData.append('bidder_phone_number', bidderPhoneNumber)
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const payloadOTP = {
      transaction_reference: bidderBidReferenceNumber,
      status: 'PENDING'
    }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const formPropertyId = form.getFieldValue('property_id')
    InstitutionUserService.BailiffTriggerRefundRequest(formData, token)
      .then((res: any) => {
        if (res.status === 201) {
          setLoadingRefund(false)
          setIsFormValidated(true)
          setTimeout(() => {
            setLoadingRefund(true)
            // void message.success('Refund Request Submitted. Waiting for being Refunded...')
          }, 5000)
          InstitutionUserService.bailiffCreateOtpCode(payloadOTP)
            .then((respo: any) => {
              if (respo.status === 200) {
                setShowOTPForm(true)
              }
            })
            // eslint-disable-next-line @typescript-eslint/no-shadow
            .catch((error: any) => {
              if (error.respo.status === 400) {
                void message.error('fail')
              }
            })
        }
        form.resetFields()
      })
      .catch((err: any) => {
        if (err?.response?.status === 400) {
          setLoadingRefund(true)
          setShowOTPForm(false)
          setShowWarningAlert(true)
          // eslint-disable-next-line max-len
          // void message.info(`Refund Request already exists with the same Transaction Reference Number: ${values.transaction_reference}`)
        }
      })
  }
  return (
    <div className="w-full">
      <div className="navbar pay-bot-border navbar-background">
        <div className="flex">
          <div className="nav-logo">
            <img src={minijust_logo} alt="Logo" />
          </div>
          <div className="nav-content text-sm md:text-2xl">
            <div className="nav-content-header">MINIJUST - IECMS</div>
            <div>Bid Security Online Payment and Refund</div>
          </div>
        </div>
        <div className="items-center font-semibold text-xs">
          <div>ENGLISH</div>
        </div>
      </div>

      <div className="scrollableContent">
        {showForm && (
          <Form name="normal_login" layout="vertical" className="login-form p-10" initialValues={{ remember: true }} autoComplete="off" onFinish={onFinish} form={form}>
            <Space direction="vertical" size={5} className="w-full content-background">
              <Card className="card-border h-full ">
                {/* eslint-disable-next-line max-len */}
                <div className="font-extrabold text-xl flex text-center items-center justify-center leading-10 text-gray-500 pb-2">
                  Please fill the form below for requesting refund
                </div>

                {/* Progress bar */}
                <Progress percent={Number(((currentStep / steps.length) * 100).toFixed(0))} />

                {/* Step name */}
                <div className="text-center font-semibold mt-3 mb-5">
                  Step {currentStep}: {steps[currentStep - 1].title}
                </div>
                {currentStep === 1 && (
                  <>
                    {/* eslint-disable-next-line max-len */}
                    <div className="green-color font-extrabold text-xl flex text-center items-center justify-center py-5">
                      Details Verification - Baillif Assigned To Auction and Bidder Winner Details
                    </div>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={4} xl={4}></Col>
                      <Col xs={24} md={8} xl={8}>
                        <Form.Item
                          label="Execution Case Number"
                          name="execution_case_number"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter execution case number'
                            }
                          ]}
                        >
                          <Input
                            maxLength={16}
                            placeholder="Enter Execution Case Number" disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8} xl={8}>
                        <Form.Item
                          label="Bailiff Assigned"
                          name="bailiff_assigned"
                          rules={[{ required: true, message: 'Please enter Bailiff Assigned' }]}
                        >
                          <Input type='tel' placeholder="Enter Bailiff Assignee" disabled/>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={4} xl={4}></Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={4} xl={4}></Col>
                      <Col xs={24} md={8} xl={8}>
                        <Form.Item
                          label="Bailiff's National ID Number"
                          name="bailiff_national_id_number"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Bailiff's National ID Number"
                            }
                          ]}
                        >
                          <Input
                            maxLength={16}
                            placeholder="Enter Bailiff's National ID Number" disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8} xl={8}>
                        <Form.Item
                          label="Bailiff Phone Number"
                          name="bailiff_phone_number"
                          rules={[{ required: true, message: 'Please enter Bailiff Phone Number' },
                            {
                              message: 'Bailiff Phone number should be either 4 digits or 10 digits.',
                              pattern: PHONE_PATTERN
                            }]}
                        >
                          <Input type='tel' placeholder="Enter Bailiff Phone Number" disabled/>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={4} xl={4}></Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={4} xl={4}></Col>
                      <Col xs={24} md={8} xl={8}>
                        <Form.Item
                          label="Bidder's Names"
                          name="bidder_full_name"
                          rules={[
                            {
                              required: true,
                              message: "Please select Bidder's Names"
                            }
                          ]}
                        >
                          <Input placeholder="Enter Bidders Names" disabled/>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8} xl={8}>
                        <Form.Item
                          label="Bidder's Phone Number"
                          name="phone_number"
                          rules={[{ required: true, message: 'Please enter Bidders Phone Number' },
                            {
                              message: 'Bidders Phone number should be either 4 digits or 10 digits.',
                              pattern: PHONE_PATTERN
                            }]}
                        >
                          <Input type='tel' placeholder="Enter Bidders Phone Number" disabled/>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={4} xl={4}></Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={4} xl={4}></Col>
                      <Col xs={24} md={8} xl={8}>
                        <Form.Item
                          label="Bidder's National ID Number"
                          name="bidder_national_id_number"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Bidder's National ID Number"
                            }
                          ]}
                        >
                          <Input
                            maxLength={16}
                            placeholder="Enter Bidder's National ID Number" disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8} xl={8}>
                        <Form.Item
                          label="Property ID"
                          name="property_id"
                          rules={[{ required: true, message: 'Please enter Property ID' },
                            {
                              message: 'Property ID should be either 4 digits or 10 digits.',
                              pattern: PHONE_PATTERN
                            }]}
                        >
                          <Input type='tel' placeholder="Enter Property ID" disabled/>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={4} xl={4}></Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={4} xl={4}></Col>
                      <Col xs={24} md={8} xl={8}>
                        <Form.Item
                          label="Bid Reference Number"
                          name="bidSecurityReference"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Bid Reference Number'
                            }
                          ]}
                        >
                          <Input
                            maxLength={16}
                            placeholder="Enter Bid Reference Number" disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8} xl={8}>
                        <Form.Item
                          // label="PRP"
                          name="prp"
                          // rules={[{ required: true, message: 'PRP is required' }]}
                        >
                          <Input className="border-none disabled:bg-white disabled:text-white disabled:p-0 font-bold disabled:cursor-default" disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={4} xl={4}></Col>
                    </Row></>)}
                {currentStep === 2 && (
                  <>
                    {/* eslint-disable-next-line max-len */}
                    <div className="green-color font-extrabold text-xl flex text-center items-center justify-center py-5">
                      Provide Payment Details
                    </div>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={4} xl={4}></Col>
                      <Col xs={24} md={8} xl={8}>
                        <Form.Item
                          label="Bid Security Paid"
                          name="amount"
                          rules={[{ required: true, message: 'Please enter Amount' }]}
                        >
                          <Input type='text' placeholder="Amount" disabled/>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8} xl={8}>
                        <Form.Item
                          label="Currency"
                          name="transaction_currency"
                          rules={[{ required: true, message: 'Please provide the transaction currency' }]}
                        >
                          <Input
                            maxLength={24}
                            placeholder="RWF" disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={4} xl={4}></Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={4} xl={4}></Col>
                      <Col xs={24} md={8} xl={8}>
                        <Form.Item
                          label="Bank Account Number"
                          name="beneficiary_account_number"
                          rules={[
                            { required: true, message: 'Please enter Bank Account Number' },
                            {
                              pattern: /^[0-9]+$/,
                              message: 'Please enter only numbers for Bank Account Number'
                            }
                          ]}
                        >
                          <Input
                            maxLength={24}
                            placeholder="Enter Bidder's Bank Account Number" disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8} xl={8}>
                        <Form.Item
                          label="Bank Name"
                          name="beneficiary_bank_name"
                          rules={[
                            {
                              required: true, message: "Please enter Bidder's Bank Name"
                            }
                          ]}
                        >
                          <Input placeholder="Beneficiary Account Name" disabled/>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={4} xl={4}></Col>
                      <Col xs={24} md={8} xl={8}>
                        <Form.Item
                          label="Bank Account Name"
                          name="beneficiary_bank_account_name"
                          rules={[{ required: true, message: 'Please enter Bank Account Name' }]}
                        >
                          <Input type='text' placeholder="Enter Bank Account Name" disabled/>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={4} xl={4}>
                        <Form.Item
                          // label="Bank Code"
                          name="bank_code"
                          rules={[
                            {
                              required: true, message: 'Bank Code not Available'
                            }
                          ]}
                        >
                          {/* eslint-disable-next-line max-len */}
                          <Input type="text"
                                 className="border-none disabled:bg-white disabled:text-white disabled:p-0 font-bold disabled:cursor-default" disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={4} xl={4}>
                        <Form.Item
                          label="Bid Security Payment Date"
                          name="bid_payment_date"
                          rules={[
                            {
                              required: true, message: 'Payment Date not Available'
                            }
                          ]}
                        >
                          {/* eslint-disable-next-line max-len */}
                          <Input placeholder="Payment Date" disabled />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={4} xl={4}></Col>
                      <Col xs={24} md={8} xl={8}>
                        <Form.Item
                          // label=" Bidder "
                          name="bidder_id"
                        >
                          {/* eslint-disable-next-line max-len */}
                          {/* <Input className="border-none disabled:bg-white disabled:text-white disabled:p-0 font-bold disabled:cursor-default placeholder:hidden" */}
                          {/*   disabled /> */}
                          <Input type='text'
                                 className="border-none disabled:bg-white disabled:text-white disabled:p-0 font-bold disabled:cursor-default" disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8} xl={8}>
                        <Form.Item
                          // label="Bid security"
                          name="bid_security_id"
                        >
                          {/* eslint-disable-next-line max-len */}
                          <Input type='text' className="border-none disabled:bg-white disabled:text-white disabled:p-0 font-bold disabled:cursor-default" disabled/>
                          {/* <Input type='text' className="border-none disabled:bg-white disabled:text-white disabled:p-0 font-bold disabled:cursor-default" disabled/> */}
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={4} xl={4}></Col>
                    </Row>
                  </>
                )}

                {currentStep === 3 && (
                  <>
                    {/* eslint-disable-next-line max-len */}
                    <div className="green-color font-extrabold text-xl flex text-center items-center justify-center py-5">
                      Attachments
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <Row gutter={[16, 16]}>
                        <Row gutter={[16, 16]}>
                          <Col xs={24} md={4} xl={4}></Col>
                          <Col xs={24} md={16} xl={16}>
                            <Form.Item
                              label="Attach Bank Slip Number"
                              name="attachment"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please attach a slip number'
                                }
                              ]}
                            >
                              <Upload
                                beforeUpload={() => false}
                                onChange={handleFileChange}
                                fileList={fileList}
                              >
                                <Button icon={<UploadOutlined />}>Upload</Button>
                              </Upload>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                    </div>
                    <Row gutter={[16, 16]}>
                      <Row gutter={[16, 16]}>
                        <Col xs={24} md={4} xl={4}></Col>
                        <Col xs={24} md={16} xl={16}>
                          <Form.Item name="declaration" rules={[{ required: false }]}>
                            <Checkbox onChange={(e) => {
                              setDeclarationChecked(e.target.checked)
                            }}>
                              {/* eslint-disable-next-line max-len */}
                              I, <b>{form.getFieldValue('bailiff_assigned')}</b>, hereby declare that the provided
                              information about <b>Account Number, Bank Account Name and Bank
                              Name</b> are true, accurate and complete.
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={4} xl={4}></Col>
                      </Row>
                      <Row justify="center" align="middle" style={{ height: '100%' }}>
                        <Col xs={24} md={24} xl={24}>
                          {showWarningAlert2 && (
                            <Modal
                              visible={showWarningAlert2}
                              onCancel={() => {
                                setShowWarningAlert2(false)
                              }}
                              footer={null}
                              centered
                            >
                              <Alert
                                message="Informational Notes"
                                /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                                description={`Refund can only be triggered for payments starting from ${targetPaymentDateValue}. Any assistance, please contact us at iecms-support@minijust.gov.rw`}
                                type="error"
                                showIcon
                                style={{
                                  width: '100%'
                                }} // Set alert background color
                                onClose={() => {
                                  setShowWarningAlert2(false)
                                }}
                              />
                            </Modal>
                          )}
                        </Col>
                      </Row>
                      <Row justify="center" align="middle" style={{ height: '100%' }}>
                        <Col xs={24} md={24} xl={24}>
                          {showDeclarationAlert && (
                            <Modal
                              visible={showDeclarationAlert}
                              onCancel={() => {
                                setShowDeclarationAlert(false)
                              }}
                              footer={null}
                              centered
                            >
                              <Alert
                                message="Warning"
                                description="Please accept the declaration before submitting."
                                type="warning"
                                showIcon
                                style={{
                                  width: '100%'
                                }} // Set alert background color
                                onClose={() => {
                                  setShowDeclarationAlert(false)
                                }}
                              />
                            </Modal>
                          )}
                        </Col>
                      </Row>
                      <Row justify="center" align="middle" style={{ height: '100%' }}>
                        <Col xs={24} md={24} xl={24}>
                          {showWarningAlert && (
                            <Modal
                              visible={showWarningAlert}
                              onCancel={() => {
                                setShowWarningAlert(false)
                              }}
                              footer={null}
                              centered
                            >
                              <Alert
                                message="Informational Notes"
                                /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                                description={`Refund Request already exists with the same Transaction Reference Number: ${bidderBidReferenceNumber}`}
                                type="info"
                                showIcon
                                style={{
                                  width: '100%'
                                }}
                                onClose={() => {
                                  setShowWarningAlert(false)
                                }}
                              />
                            </Modal>
                          )}
                        </Col>
                      </Row>
                      <Row justify="center" align="middle" style={{ height: '100%' }}>
                        <Col xs={24} md={24} xl={24}>
                          {showSuccessAlert && (
                            <Modal
                              visible={showSuccessAlert}
                              onCancel={() => {
                                setShowSuccessAlert(false)
                              }}
                              footer={null}
                              centered
                            >
                              <Alert
                                message="Informational Notes"
                                /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                                description="Refund Request Submitted. Waiting to be Refunded..."
                                type="info"
                                showIcon
                                style={{
                                  width: '100%'
                                }} // Set alert background color
                                onClose={() => {
                                  setShowSuccessAlert(false)
                                }}
                              />
                            </Modal>
                          )}
                        </Col>
                      </Row>
                    </Row>
                  </>
                )}

                {/* Buttons for navigating between steps */}
                <Row gutter={[16, 16]} justify="center">
                  <Col xs={24} md={8} xl={8} className="text-right">
                    {currentStep > 1 && (
                      <Button onClick={prevStep}>Previous</Button>
                    )}
                  </Col>
                  <Col xs={24} md={8} xl={8} className="text-left">
                    {currentStep < 3 && (
                      <Button type="primary" onClick={nextStep}>Next</Button>
                    )}
                    {currentStep === 3 && (
                      <Button type="primary" htmlType="submit">
                        {isEdit ? 'Update' : 'Continue'}
                      </Button>
                      // <Button type="primary" htmlType="submit">Submit</Button>
                    )}
                  </Col>
                  <Col xs={24} md={8} xl={8}></Col>
                </Row>
              </Card>
            </Space>
          </Form>
        )}
        {/* Step 3: Render OTP form conditionally */}
        <Modal
          title="Enter the provided code to submit your Request"
          visible={showOTPForm && isFormSubmitted && isFormValidated && declarationChecked}
          onCancel={handleOTPFormCancel}
          footer={[
            <Button key="cancel" onClick={handleOTPFormCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={handleOTPSubmit}>
              Submit
            </Button>
          ]}
          className="custom-modal"
          keyboard={false}
          maskClosable={false}
        >
          <Form.Item
            name="otp"
          >
            {/* OTP input field */}
            <Input placeholder="Enter Code" value={otpValue} onChange={handleInputChange}/>
          </Form.Item>
          <p className="msgOTP">

            We just sent you an SMS on this phone number: ********{phone_number.slice(-2)} with
            an OTP
            (One Time Password). Enter the OTP in the form above to confirm your
            request.{' '}
          </p>
          <br/>
          <p>
            The OTP will expire in 5 minutes
            <code className="font-semibold ml-2">
              {moment(countdown).format('mm:ss')}</code>.{' '}
            {countdown === 0 && <Button type="link">Click here to resend SMS.</Button>}
          </p>
        </Modal>
        <Modal
          title="Notification"
          visible={showBankCodeModal}
          onCancel={() => {
            setShowBankCodeModal(false)
            setShowCancelMessage(true)
          }}
          footer={null}
          centered
        >
          {isVisible && (
            <><p style={{
              fontWeight: 'bold',
              color: '#333'
            }}>Please wait while fetching...</p>
              <hr style={{
                borderTop: '2px solid #ccc',
                margin: '10px 0'
              }}/>
              <p style={{
                fontWeight: 'bold',
                fontSize: '14px',
                color: '#333'
              }}>
                Mutegereze gato mu gihe turi kureba amakuru ajyanjye ni ingwate ya cyamunara muri
                gusaba...
              </p></>
          )}
          {showHello &&
            <p><b>Assigned Bailiff info is missing:</b><br></br>
              Make sure that the <b>National ID</b> under your account <b>My Profile</b> is written
              correctly, and the phone number in the <b>Phone</b> field is written properly and
              registered under the provided National ID.
              <br></br> <b>Notice:</b> Allowed phone number format: <b>07xxxxxxxx</b><br></br>
              <hr style={{
                border: '1px solid #ccc',
                margin: '20px 0',
                width: '80%',
                backgroundColor: '#ccc'
              }}></hr>
              Banza ureba niba Nimero y’indangamuntu iri muri “My Profile” hamwe na telephone
              iyibaruyeho byanditse neza. Telefone igomba kuba yanditse gutya: <b>07xxxxxxxx</b>
            </p>
          }
        </Modal>
        <Modal
          title={codeBank === '040' ? 'Bid Security Refund Amount' : 'Bank Transfer Charges'}
          visible={showBankTransferChargeModal && bailiffId !== null && form.getFieldValue('bailiff_assigned') !== null}
          onCancel={() => {
            setShowBankTransferChargeModal(false)
            setShowCancelMessage(true)
          }}
          footer={[
            <Button key="cancel" onClick={() => {
              setShowBankTransferChargeModal(false)
              setShowCancelMessage(true)
            }}>
              Cancel
            </Button>,
            <Button type="primary" key="ok" onClick={handleAgree}>
              I Agree
            </Button>
          ]}
          keyboard={false}
          maskClosable={false}
          className="custom-modal"
        >
          <p>
            {/* eslint-disable-next-line max-len */}
            You will be charged <strong>{codeBank !== '040' ? '1,000 Rwf (BK to other Banks).' : '0 Rwf (BK to BK)'}</strong>.
          </p>

          <p>
            {/* eslint-disable-next-line max-len */}
            Total bid Security Paid: <strong>{parseInt(String(paymentAmount)).toLocaleString()} Rwf </strong>
          </p>

          <p>
            Amount Received in Your Account after Transfer: <strong>
            {/* eslint-disable-next-line max-len */}
            {parseInt(String(Number(paymentAmount) - (codeBank !== '040' ? 1000 : 0))).toLocaleString()} Rwf
          </strong>
          </p>
        </Modal>
        {showCancelMessage && (
          <div className="cancel-message">
            <div className="separator"></div>
            <p>
              <h3>Thank you for using our services.</h3>
            </p>
            <div className="separator"></div>
            <p>For any other support, please contact us on
              <span><strong>iecms-support@minijust.gov.rw</strong></span>.</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default BaillifRefundRequest
