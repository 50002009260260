import { Alert, Button, Card, Col, Form, Input, message, Modal, Row, Spin } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { PHONE_PATTERN } from '../../constants/general'
import InstitutionUserService from '../../services/InstitutionUserService'

const RequestPaymentClaim: React.FC = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}')
  const [form] = Form.useForm()
  const [loadingRefund, setLoadingRefund] = useState(false)
  const [declarationChecked, setDeclarationChecked] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [biddersStatus, setBiddersStatus] = useState(false)
  const [showOTPForm, setShowOTPForm] = useState(false)
  const [phone_number, setPhoneNumber] = useState('')
  const [showWarningAlert, setShowWarningAlert] = useState(false)
  const [showWarningAlert2, setShowWarningAlert2] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [showCancelMessage, setShowCancelMessage] = useState(false)
  const [targetPaymentDateValue, setTargetPaymentDateValue] = useState(false)
  const [paymentDate, setPaymentDate] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [token, setToken] = useState('')
  const [bidderReferenceNumber, setBidderReferenceNumber] = useState('')
  const [BiddersAmountRequestedToBeRefund, setBiddersAmountRequestedToBeRefund] = useState('')
  const [BailiffAmountRequestedToBeRefunded, setBailiffAmountRequestedToBeRefunded] = useState('')
  const [NewBiddersAmountRequestedToBeRefunded, setNewBiddersAmountRequestedToBeRefunded] = useState('')
  const [NewBailiffAmountRequestedToBeRefunded, setNewBailiffAmountRequestedToBeRefunded] = useState('')
  const [ipAddress, setIpAddress] = useState<string>('')
  const [deviceName, setDeviceName] = useState<string>('')
  const [TotalPayments, setTotalPayments] = useState('')
  const [generatedLink, setGeneratedLink] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [isRefundExist, setIsRefundExist] = useState(false)
  const [loadingCheck, setLoadingCheck] = useState(false)
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleGenerateLink = () => {
    // const link = `http://10.8.126.222/refund-request/bidSecurityReference/${bidderReferenceNumber}`
    // const link = `http://localhost:3000/refund-request/bidSecurityReference/${bidderReferenceNumber}`
    const link = `https://payment.iecms.gov.rw/refund-request/bidSecurityReference/${bidderReferenceNumber}`
    setGeneratedLink(link)
    alert(`Generated Link: ${link}`)
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleContinue = () => {
    if (declarationChecked) {
      setShowOTPForm(true)
    }
  }
  useEffect(() => {
    // Fetch IP Address
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchIpAddress = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json')
        const data = await response.json()
        setIpAddress(data.ip)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch IP address:', error)
      }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchDeviceName = () => {
      const platform = navigator.platform
      const userAgent = navigator.userAgent
      setDeviceName(`${platform} - ${userAgent}`)
    }

    void fetchIpAddress()
    fetchDeviceName()
  }, [])
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function fetchRefundStats () {
      setLoading(true)
      try {
        const response = await InstitutionUserService.viewRefundStatsByRefundStartDate(fromDate, toDate, token)
        const biddersAmountRequestedToBeRefund = response.data?.biddersAmountRequestedToBeRefund
        setBiddersAmountRequestedToBeRefund(response.data?.biddersAmountRequestedToBeRefund)
        const bailiffAmountRequestedToBeRefunded = response.data?.bailiffAmountRequestedToBeRefunded
        setBailiffAmountRequestedToBeRefunded(response.data?.bailiffAmountRequestedToBeRefunded)
        const newBiddersAmountRequestedToBeRefunded = response.data?.newBiddersAmountRequestedToBeRefunded
        setNewBiddersAmountRequestedToBeRefunded(response.data?.newBiddersAmountRequestedToBeRefunded)
        const newBailiffAmountRequestedToBeRefunded = response.data?.newBailiffAmountRequestedToBeRefunded
        setNewBailiffAmountRequestedToBeRefunded(response.data?.newBailiffAmountRequestedToBeRefunded)
        const totalPayments = response.data?.totalPayments
        setTotalPayments(response.data?.totalPayments)
      } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        setError('Failed to fetch refund stats')
        // eslint-disable-next-line no-console
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    void fetchRefundStats()
  },
  [fromDate, toDate, token])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const checkRefundRequest = async () => {
      if (bidderReferenceNumber === '') return

      setLoadingCheck(true)
      try {
        const response = await InstitutionUserService.viewBailiffRefundRequestByReferenceNumber(bidderReferenceNumber)

        if (response?.status === 200 && Boolean(response.data)) {
          setIsRefundExist(true)
          setShowForm(false)
          Modal.info({
            title: 'Refund Request Exists',
            content: 'A refund request already exists for this reference number.',
            onOk: () => { setIsRefundExist(false) }
          })
        } else {
          setShowForm(true)
          void message.success('No existing refund request found. You can proceed.')
        }
      } catch (error) {
        setShowForm(true)
        // void message.error('Error checking refund request. Please try again.')
        // eslint-disable-next-line no-console
        console.error('Error:', error)
      } finally {
        setLoadingCheck(false)
      }
    }
    void checkRefundRequest()
  }, [bidderReferenceNumber])

  const handleReferenceChange = async (value: string): Promise<void> => {
    setBidderReferenceNumber(value)
    if (value !== '') {
      setLoadingRefund(true)
      try {
        const paymentData = await InstitutionUserService.loadBidsDetailsForRefund(value)
        if (paymentData.status === 200) {
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-shadow
          const encryptReferenceNumber = (value: any) => {
            // Generate a random string to add
            const randomString = Math.random().toString(36).substring(2, 8) // Adjust length as needed
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            const combinedValue = `${randomString}-${value}-${randomString}`
            const utf8Value = unescape(encodeURIComponent(combinedValue))
            return btoa(utf8Value)
          }
          const encryptedReference = encryptReferenceNumber(paymentData.data?.ref_number)
          // const generate_link = `http://10.8.126.222/refund-request/bidSecurityReference/${bidderReferenceNumber}`
          // const generate_link = `http://localhost:3000/refund-request/requestBidSecurityFees/${encryptedReference}`
          const generate_link = `https://payment.iecms.gov.rw/refund-request/requestBidSecurityFees/${encryptedReference}`
          form.setFieldsValue({
            bidder_id: paymentData.data?.bid?.bidder?.bidder_id,
            bid_security_id: paymentData.data?.payment?.bid_security_id,
            amount: paymentData.data?.payment?.amount,
            transaction_currency: paymentData.data?.payment?.currency,
            transaction_reference: paymentData.data?.ref_number,
            phone_number: paymentData.data?.bid?.bidder?.phone_number,
            bidder_national_id_number: paymentData.data?.bid?.bidder?.nid,
            property_id: paymentData.data?.bid?.auction?.property_id,
            execution_case_number: paymentData.data?.bid?.auction?.execution_case_no,
            fullname: paymentData.data?.bid?.bidder?.full_name,
            email: paymentData.data?.bid?.bidder?.email,
            payment_date: paymentData.data?.payment?.payment_date,
            generate_link
          })
          setPaymentDate(paymentData.data?.payment?.payment_date)
          setBiddersStatus(paymentData.status)
        } else {
          throw new Error('No data found for the given reference number.')
        }
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : 'An unexpected error occurred.'
        Modal.error({
          title: 'Error',
          content: errorMessage
        })
      } finally {
        setLoadingRefund(false)
      }
    }
  }
  // Handle search button click
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSearchClick = () => {
    const referenceNumber = form.getFieldValue('bid_reference_number')
    void handleReferenceChange(referenceNumber)
    setShowForm(true)
    setBidderReferenceNumber(form.getFieldValue('bid_reference_number'))
    // const link = `http://10.8.126.222/refund-request/bidSecurityReference/${bidderReferenceNumber}`
    // const link = `http://localhost:3000/refund-request/bidSecurityReference/${bidderReferenceNumber}`
    const link = `https://payment.iecms.gov.rw/refund-request/bidSecurityReference/${bidderReferenceNumber}`
    setGeneratedLink(link)
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const onFinish = async (values: any): void => {
    const paymentDateTime = moment(paymentDate, 'YYYY-MM-DD HH:mm:ss')
    const targetPaymentDate = moment('2022-07-25', 'YYYY-MM-DD')
    // Check if payment date is valid for a refund
    if (!paymentDateTime.isSameOrAfter(targetPaymentDate)) {
      setLoadingRefund(false)
      setShowWarningAlert2(true)
      // Optionally show a warning message
      return
    }
    // Construct the payload for the refund request
    const payload = {
      RefundRequest: {
        transaction_reference: values.transaction_reference,
        debit_account_number: '000400696575429',
        debit_account_name: 'MINIJUST AUCTION FUNDS MAF',
        transaction_currency: values.transaction_currency,
        reason: 'Did not participate in the bidding process but was paid.',
        status: 'PENDING',
        bidder_id: form.getFieldValue('bidder_id'),
        bid_security_id: form.getFieldValue('bid_security_id'),
        phone_number: values.phone_number,
        email: form.getFieldValue('email'),
        link: form.getFieldValue('generate_link'),
        created_by: userInfo.full_name
      }
    }
    try {
      const body = {
        transaction_reference: bidderReferenceNumber
      }
      const validateRequest = await InstitutionUserService.validateRefundRequest(body)
      if (validateRequest === null || validateRequest.status === 200) {
        void message.info('Request Exists')
        return
      }
    } catch (error) {
      // void message.error('An error occurred while sending the refund request. Please try again later.')
      // Send the refund request
      void message.success(
        'Refund request Initiated successfully. Waiting for Bidder to Request refund...'
      )
      const res = await InstitutionUserService.SendRefundRequestLink(payload, token)
      if (res.status === 201) {
        void message.success(
          'Refund request Initiated successfully. Waiting for Bidder to Request refund...'
        )
      } else {
        void message.error('Failed to submit the refund request. Please try again.')
      }
    } finally {
      setLoadingRefund(false)
    }
  }

  return (
    <div className="w-full">
      <div className="links-container">
        <div className="link-item">
          <a
            href="/update-claim-refund-request"
            className="link"
          >
            <i className="fas fa-sync"></i> Claim Refund Request (Bailiff/Bidders)
          </a>
        </div>
        <span className="separator">||</span>
        <div className="link-item">
          <a
            href="/view-list"
            className="link"
          >
            <i className="fas fa-list"></i> View Claim Requests List
          </a>
        </div>
        <span className="separator">||</span>
        <div className="link-item">
          <a
            href="/update-for-public-treasury"
            className="link"
          >
            <i className="fas fa-list"></i> Update for Public Treasury
          </a>
        </div>
      </div>
      <div>
        {loadingCheck && <p>Loading...</p>}
        {isRefundExist && <p>Refund exists for this reference number.</p>}
      </div>

      <div className="header-stat" style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        gap: '20px'
      }}>
        <div className="stat-item" style={{
          backgroundColor: '#f4f7fb',
          padding: '10px 20px',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          flex: '1 1 20%',
          textAlign: 'center',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          color: '#333'
        }}>
          <strong> Total Amount Requested: </strong>
          <strong>
            {
              ((Number(BailiffAmountRequestedToBeRefunded) ?? 0) +
                (Number(BiddersAmountRequestedToBeRefund) ?? 0)).toLocaleString()
            } Rwf
          </strong>
        </div>

        <div className="stat-item" style={{
          backgroundColor: '#e0f7fa',
          padding: '10px 20px',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          flex: '1 1 20%',
          textAlign: 'center',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          color: '#333'
        }}>
          <strong>New Amount Requested: </strong>
          <strong>
            {
              ((Number(NewBiddersAmountRequestedToBeRefunded) ?? 0) +
                (Number(NewBailiffAmountRequestedToBeRefunded) ?? 0)).toLocaleString()
            } Rwf
          </strong>
        </div>

        <div className="stat-item" style={{
          backgroundColor: '#fff3e0',
          padding: '10px 20px',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          flex: '1 1 20%',
          textAlign: 'center',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          color: '#333'
        }}>
          <strong>Remain to
            Request: </strong>
          <strong>
            {(Number(TotalPayments) - ((Number(BiddersAmountRequestedToBeRefund) +
              Number(BailiffAmountRequestedToBeRefunded)))).toLocaleString()} Rwf
          </strong>
        </div>

        <div className="stat-item" style={{
          backgroundColor: '#e8f5e9',
          padding: '10px 20px',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          flex: '1 1 20%',
          textAlign: 'center',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          color: '#333'
        }}>
          <strong>Total Paid Amount: </strong>
          <strong>
            {(Number(TotalPayments)).toLocaleString()} Rwf
          </strong>
        </div>

        <div className="stat-item" style={{
          backgroundColor: '#fff9c4',
          padding: '10px 20px',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          flex: '1 1 20%',
          textAlign: 'center',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          color: '#333'
        }}>
          <strong>Total refunded Amount: </strong>
          <strong>
            {(((Number(BiddersAmountRequestedToBeRefund) + Number(BailiffAmountRequestedToBeRefunded))) - ((Number(NewBiddersAmountRequestedToBeRefunded)) + (Number(NewBailiffAmountRequestedToBeRefunded)))).toLocaleString()} Rwf
          </strong>
        </div>
      </div>

      <div className="division-title p-4">
        <Row justify="start" gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h2 className="responsive-title">
              Provide the bid security reference number so the link can be sent to bidders,
              allowing them to request 5% of the bid security paid.
            </h2>
          </Col>
        </Row>
      </div>

      <div className="request-ref p-4">
        <Form
          name="normal_login"
          layout="vertical"
          className="login-form p-10"
          autoComplete="off"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Card className="bg-slate-100 p-4">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  label="Bid Reference Number"
                  name="bid_reference_number"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Bid Reference Number'
                    },
                    {
                      pattern: /^[A-Za-z\d!@#$%^&*()_+={}[\]:;"'<>,.?/~`\\|-]{7}$/,
                      message:
                        'Bid Reference Number must be exactly 7 characters long and can include letters, digits, and special characters.'
                    }
                  ]}
                >
                  <Input type="tel" placeholder="Enter Bid Reference Number"/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}></Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8} lg={6}></Col>
              <Col xs={24} sm={12} md={8} lg={6}></Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Button
                  type="primary"
                  onClick={handleSearchClick}
                  loading={loadingRefund}
                  disabled={isRefundExist}
                  block
                >
                  Search
                </Button>
              </Col>
            </Row>

            <div className="spacing-row">
              {showForm && biddersStatus && (
                <p>
                  View Bidders Details and Generate Link for Bidder to request 5% of bid
                  Security.
                </p>
              )}
            </div>

            {showForm && biddersStatus && (
              <div>
                {/* Additional form rows */}
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Phone Number"
                      name="phone_number"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Phone Number'
                        },
                        {
                          pattern: PHONE_PATTERN,
                          message: 'Phone number should be 10 digits.'
                        }
                      ]}
                    >
                      <Input type="tel" placeholder="Enter Phone Number" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                      label="National ID"
                      name="bidder_national_id_number"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Your National ID'
                        },
                        { message: 'National ID should be 16 digits.' }
                      ]}
                    >
                      <Input placeholder="National ID is missing" maxLength={16} disabled/>
                    </Form.Item>
                  </Col>
                </Row>

                {/* More form fields can be placed similarly */}
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Property ID"
                      name="property_id"
                      rules={[{
                        required: true,
                        message: 'Please enter Property ID'
                      }]}
                    >
                      <Input placeholder="Enter Property ID" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                      label="Execution Case Number"
                      name="execution_case_number"
                      rules={[{
                        required: true,
                        message: 'Please enter Execution Case Number'
                      }]}
                    >
                      <Input placeholder="Execution Case Number" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}></Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Transaction Reference"
                      name="transaction_reference"
                      rules={[{
                        required: true,
                        message: 'Please enter transaction reference'
                      }]}
                    >
                      <Input placeholder="Bid Reference Number" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                      label="Bidder's Names"
                      name="fullname"
                      rules={[{
                        required: true,
                        message: 'Please enter Bidders Name'
                      }]}
                    >
                      <Input placeholder="Bidders Name" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}></Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Deposit Bid Security Amount"
                      name="amount"
                      rules={[{
                        required: true,
                        message: 'Please enter Amount'
                      }]}
                    >
                      <Input type="text" placeholder="Amount" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                      label="Currency"
                      name="transaction_currency"
                      rules={[{
                        required: true,
                        message: 'Please provide the transaction currency'
                      }]}
                    >
                      <Input maxLength={24} placeholder="RWF" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}></Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} lg={8}>
                    <Form.Item
                      label="Bid Security Payment Date"
                      name="payment_date"
                      rules={[{
                        required: true,
                        message: 'Payment Date not Available'
                      }]}
                    >
                      <Input placeholder="Bid Payment Date" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[{
                        required: true,
                        message: 'Email not Available'
                      }]}
                    >
                      <Input placeholder="Bidder Email" disabled/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      // label=" Bidder "
                      name="bidder_id"
                    >
                      <Input
                        className="border-none disabled:bg-white disabled:text-white disabled:p-0 font-bold disabled:cursor-default placeholder:hidden"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      // label="Bid security"
                      name="bid_security_id"
                    >
                      <Input
                        type="text"
                        className="border-none disabled:bg-white disabled:text-white disabled:p-0 font-bold disabled:cursor-default"
                        disabled
                      />
                    </Form.Item>
                    {loadingRefund && <Spin size="large" />}
                  </Col>
                  <Col xs={24} md={4} xl={4}></Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={8} lg={6}></Col>
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={loadingRefund}
                        onClick={handleContinue}
                        block
                      >
                        {isEdit ? 'Update' : 'Submit'}
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}></Col>
                </Row>
              </div>
            )}
          </Card>
        </Form>
        {loadingCheck && <Spin />}
        {isRefundExist && <Alert message="Refund request already exists." type="info" />}
      </div>

      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Col xs={24} md={24} xl={24}>
          {showSuccessAlert && (
            <Modal
              visible={showSuccessAlert}
              onCancel={() => {
                setShowSuccessAlert(false)
              }}
              footer={null}
              centered
            >
              <Alert
                message="Informational Notes"
                /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                description="Refund Request Submitted. Waiting to be Refunded..."
                type="info"
                showIcon
                style={{
                  width: '100%'
                }} // Set alert background color
                onClose={() => {
                  setShowSuccessAlert(false)
                }}
              />
            </Modal>
          )}
        </Col>
      </Row>
      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Col xs={24} md={24} xl={24}>
          {showWarningAlert && (
            <Modal
              visible={showWarningAlert}
              onCancel={() => {
                setShowWarningAlert(false)
              }}
              footer={null}
              centered
            >
              <Alert
                message="Informational Notes"
                /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                description={
                  'Refund Request already exists with the same Transaction Reference Number:'
                }
                type="info"
                showIcon
                style={{
                  width: '100%'
                }} // Set alert background color
                onClose={() => {
                  setShowWarningAlert(false)
                }}
              />
            </Modal>
          )}
        </Col>
      </Row>
      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Col xs={24} md={24} xl={24}>
          {showWarningAlert2 && (
            <Modal
              visible={showWarningAlert2}
              onCancel={() => {
                setShowWarningAlert2(false)
              }}
              footer={null}
              centered
            >
              <Alert
                message="Informational Notes"
                /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                description={`Refund can only be triggered for payments starting from ${targetPaymentDateValue}. Any assistance, please contact us at iecms-support@minijust.gov.rw`}
                type="error"
                showIcon
                style={{
                  width: '100%'
                }} // Set alert background color
                onClose={() => {
                  setShowWarningAlert2(false)
                }}
              />
            </Modal>
          )}
        </Col>
      </Row>
      {showCancelMessage && (
        <div className="cancel-message">
          <div className="separator"></div>
          <p>
            <h3>Thank you for using our services.</h3>
          </p>
          <div className="separator"></div>
          <p>
            For any other support, please contact us on
            <span>
              <strong> iecms-support@minijust.gov.rw</strong>
            </span>
            .
          </p>
        </div>
      )}
    </div>
  )
}

export default RequestPaymentClaim
