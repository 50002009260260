/* eslint-disable */
import { Col, Modal, Row } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'

interface AuditLog {
  action: string;
  created_at: string;
  created_by: string;
  record_id: string | null;
}

interface RefundRequest {
  auditLogs: AuditLog[] | undefined;
  // Include other fields you might be using here
}

const ViewRefundRequest = () => {
  const navigate = useNavigate()
  const [isModalVisible, setIsModalVisible] = useState(false); // State to manage modal visibility
  const [isFooterVisible, setIsFooterVisible] = useState(false); // State to toggle div visibility
  const { state } = useLocation()
  const refundRequest = state || {}
  const toggleFooterDetails = () => {
    setIsFooterVisible(prevState => !prevState); // Toggle visibility
  };
  const toggleModalVisibility = () => {
    setIsModalVisible(!isModalVisible);
  };
  const bailiffRefundRequest = state || {}
  const formatDate = ({ dateString }: { dateString: any }) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
  };
  return (
    <div className="w-full p-body">
      <div className="px-5 py-2.5 border-b-slate-200 border border-solid ">
        <div className="flex justify-between items-center">
          <div className="text-xl font-bold">
            <span
              className="cursor-pointer"
              onClick={() => {
                navigate('/refund-request-list');
              }}
              style={{ color: '#37762f' }} // Apply the custom color here
            >
              Refund Request
            </span>{' '}
            {'> '}
            <span className="font-normal">{refundRequest?.beneficiary_account_name}</span>
          </div>

        </div>
      </div>
      <div className="px-7 py-4">
        {/* Link to open the modal */}
        <div
          className={`cursor-pointer text-white px-6 py-3 rounded-full transition-all duration-300 
    ${isModalVisible ? 'shadow-lg transform scale-105' : 'hover:bg-green-400'}`}
          style={{ backgroundColor: isModalVisible ? '#37762f' : '#6b8e3d' }} // #37762f for active, #6b8e3d for hover
          onClick={toggleModalVisibility}
        >
          {isModalVisible ? 'Close Logs' : 'Show Logs'}
        </div>
        <div className="bg-slate-100 p-4">
          <div className="green-color font-extrabold text-base flex items-center leading-10">
            Beneficiary Refund Request Details
          </div>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Beneficiary Account Name</div>
              <div className="text-base font-medium">{refundRequest?.beneficiary_account_name}</div>
            </Col>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Beneficiary Bank Name</div>
              <div className="text-base font-medium">{refundRequest?.beneficiary_bank_name}</div>
            </Col>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Beneficiary Account number</div>
              <div
                className="text-base font-medium">{refundRequest?.beneficiary_account_number}</div>
            </Col>
          </Row>
          <div className="pt-4">
            <Row gutter={16}>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Debit Account Number</div>
                <div className="text-base font-medium">{refundRequest?.debit_account_number}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Debit Account Name</div>
                <div className="text-base font-medium">{refundRequest?.debit_account_name}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Transaction Reference</div>
                <div className="text-base font-medium">{refundRequest?.transaction_reference}</div>
              </Col>
            </Row>
          </div>
          <div className="pt-4">
            <Row gutter={16}>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Amount to be refunded</div>
                <div
                  className="text-base font-medium">{parseInt(String(refundRequest?.amount)).toLocaleString()}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Transaction currency</div>
                <div className="text-base font-medium">{refundRequest?.transaction_currency}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Reason</div>
                <div className="text-base font-medium">{refundRequest?.reason}</div>
              </Col>
            </Row>
          </div>
          <div className="pt-4">
            <Row gutter={16}>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Status</div>
                <div className="text-base font-medium">{refundRequest?.status}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Requested Date</div>
                <div
                  className="text-base font-medium">{formatDate({ dateString: refundRequest?.created_at })}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Updated Date</div>
                <div
                  className="text-base font-medium">{formatDate({ dateString: refundRequest?.updated_at })}</div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div className="px-7 py-4">
        <div className="bg-slate-100 p-4">
          <div className="green-color font-extrabold text-base flex items-center leading-10">
            Bidder Refund Request Details
          </div>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Bidder Name</div>
              <div className="text-base font-medium">{refundRequest?.bidders.full_name}</div>
            </Col>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Bidder ID</div>
              <div className="text-base font-medium">{refundRequest?.bidders.nid}</div>
            </Col>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Bidder Phone Number</div>
              <div
                className="text-base font-medium">{refundRequest?.bidders.phone_number}</div>
            </Col>
          </Row>
          <div className="pt-4">
            <Row gutter={16}>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Bidder Email</div>
                <div className="text-base font-medium">{refundRequest?.bidders.email}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Reference Number</div>
                <div className="text-base font-medium">{refundRequest?.bid_securities.ref_number}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Amount Paid for Bid Security</div>
                <div className="text-base font-medium">{refundRequest?.bid_securities.amount_covered}</div>
              </Col>
            </Row>
          </div>
        </div>
        {/* Modal to display logs */}
        <Modal
          visible={isModalVisible}
          onCancel={toggleModalVisibility}
          footer={null}
          width="60%"
          centered
          title={
            <div
              style={{
                backgroundColor: '#37762f', // Set green background color
                color: 'white',             // Set text color to white
                fontSize: '14px',           // Corrected: Use fontSize for font size
                padding: '10px',            // Add padding for better appearance
                fontWeight: 'bold',         // Optional: Make the title bold
              }}
            >
              Refund Request Logs
            </div>
          }
        >
          <div className="footer-details mt-4 p-4 bg-slate-100">
            <div className="text-lg font-extrabold text-green-600 leading-10">
              Refund Request Logs
            </div>

            {/* Created Logs */}
            <div className="pt-4">
              <div className="text-xl font-semibold">Created Logs</div>

              {/* Loop through the auditLogs array */}
              {refundRequest?.auditLogs?.map((log: AuditLog, index: number) => (
                <Row key={index} gutter={[16, 16]}>
                  <Col xs={24} xl={6}>
                    <div className="log-label">Action:</div>
                    <div className="log-content">{log?.action}</div>
                  </Col>
                  <Col xs={24} xl={6}>
                    <div className="log-label">Created By:</div>
                    <div className="log-content">{log?.created_by}</div>
                  </Col>
                  <Col xs={24} xl={6}>
                    <div className="log-label">Created At:</div>
                    <div className="log-content">{formatDate({ dateString: log?.created_at })}</div>
                  </Col>
                  <Col xs={24} xl={6}>
                    <div className="log-label">Record ID:</div>
                    <div className="log-content">{log?.record_id ?? 'N/A'}</div>
                  </Col>
                </Row>
              ))}
            </div>

          </div>
        </Modal>
      </div>
    </div>
  )
}

export default ViewRefundRequest
